<form clrForm [formGroup]="searchForm">
  <div class="clr-row">
    <div class="clr-col-6">
      <subs-input
        controlName="wUPILastName"
        label="WU PI Last Name"
        helperText="Will return projects that contain the entered name."
      >
      </subs-input>

      <subs-input
        controlName="proposalId"
        label="Proposal ID"
        helperText="Will return projects that contain the entered ID."
      >
      </subs-input>

      <subs-input
        label="Grant Worktag"
        placeholder="Alphanumeric"
        controlName="grantWorkTag"
        helperText="Will return projects that contain the entered Grant Worktag."
      >
      </subs-input>

      <subs-input
        controlName="sponsorName"
        helperText="Will return projects that contain the entered
        sponsor name."
      >
      </subs-input>

      <subs-input
        controlName="subPILastName"
        label="Sub PI Last Name"
        helperText="Will return projects that contain the entered
        contact."
      >
      </subs-input>

      <subs-select
        class="clr-col"
        controlName="foreignEntity"
        label="Foreign Institution"
      >
        <option value=""></option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </subs-select>

      <div class="clr-row">
        <div class="clr-col-12">
          <subs-assigned-ga controlName="assignedGA" label="OSRS GA">
          </subs-assigned-ga>
        </div>
      </div>
    </div>

    <div class="clr-col-6">
      <subs-input
        controlName="wUPIFirstName"
        label="WU PI First Name"
        helperText="Will return projects that contain the entered
        name."
      >
      </subs-input>

      <subs-input
        controlName="awardDept"
        helperText="Will return projects that contains the entered text."
      >
      </subs-input>

      <subs-input
        label="PO Number"
        controlName="poNumber"
        helperText="Will return projects that contains the entered number."
      >
      </subs-input>

      <subs-input
        label="PI Cost Center"
        placeholder="Alphanumeric"
        controlName="costCenterId"
        helperText="Will return projects that contain the entered PI Cost Center associated with the Grant Worktag."
      >
      </subs-input>

      <subs-input
        controlName="subrecipient"
        helperText="Will return projects that contains the entered text."
      >
      </subs-input>

      <subs-subaward-status-select
        class="clr-col-lg-auto"
        containerStyle="margin-top: 0"
        controlName="status"
        [statuses]="activeStatusList"
        [includeEmptyStatus]="true"
        [displayOSRSStatus]="searchForm.controls.OSRSStatus.value == true"
      >
      </subs-subaward-status-select>

      <subs-checkbox label="Include OSRS Statuses" controlName="OSRSStatus">
      </subs-checkbox>
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-11" style="margin-top: 24px">
      <subs-alert [alertBehavior]="alertSubject$"> </subs-alert>
      <button
        class="btn btn-primary"
        [clrLoading]="submitState | async"
        (click)="search()"
        data-test-id="search-btn"
        [disabled]="!searchForm.valid"
      >
        Search
      </button>

      <button
        class="btn btn-outline"
        (click)="clear()"
        data-test-id="clear-btn"
      >
        Clear
      </button>
      <button
        *ngIf="searchResult.length"
        class="btn btn-outline"
        [clrLoading]="submitState | async"
        (click)="downloadReport()"
        data-test-id="generate-report-btn"
      >
        Export
      </button>
    </div>
    <div class="clr-col-1" style="margin-top: 24px; padding-top: 24px">
      <span *ngIf="searchResult && searchResult.length >= 0">{{
        searchResultCount
      }}</span>
    </div>
  </div>
  <div
    *ngIf="shouldShowNoResultsMessage | async"
    class="alert alert-warning"
    role="alert"
  >
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
        </div>
        <span class="alert-text" data-test-id="alert-msg">
          There were no results for your search.
        </span>
      </div>
    </div>
  </div>

  <div
    *ngIf="shouldShowMaxResultsMessage | async"
    class="alert alert-warning"
    role="alert"
  >
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
        </div>
        <span class="alert-text" data-test-id="alert-msg">
          Only 300 projects are being shown. Export to see the whole list
        </span>
      </div>
    </div>
  </div>
</form>

<subs-da-worklist-result-grid
  [searchResults]="searchResult"
  [statuses]="activeStatusList"
  data-test-id="search-result-grid"
>
</subs-da-worklist-result-grid>
