<clr-datagrid>
  <clr-dg-column
    [style.width.px]="130"
    [clrDgField]="'proposalId'"
    [clrDgSortOrder]="ascSort"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Proposal ID
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'assignedGA'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      OSRS GA
    </ng-container></clr-dg-column
  >
  <clr-dg-column [style.width.px]="140" [clrDgField]="'wUPILastName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      WU PI Last Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'wUPIFirstName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      WU PI First Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'sponsorName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Sponsor Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'grantWorkTag'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Grant Worktag
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'costCenterId'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      PI Cost Center
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'poNumber'"
  ><ng-container *clrDgHideableColumn="{ hidden: false }">
    PO Number
  </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'awardYear'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Award Year
    </ng-container></clr-dg-column
  >
  <clr-dg-column [style.width.px]="150" [clrDgField]="'subrecipient'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Subrecipient
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'foreignEntity'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Foreign Institution
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'subPILastName'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Sub PI Last Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'status'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Status
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'submittedDate'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Submitted
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'returnedDate'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Returned
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'awardDept'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Award Dept
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'agreementNum'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Agreement Num
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'trackingNumber'"
    ><ng-container *clrDgHideableColumn="{ hidden: false }">
      Tracking Num
    </ng-container></clr-dg-column
  >

  <clr-dg-row
    *clrDgItems="let result of searchResults"
    (click)="navigateToSub(result.subrecipientId)"
  >
    <clr-dg-cell>{{ result.proposalId }}</clr-dg-cell>
    <clr-dg-cell>{{ result.assignedGA }}</clr-dg-cell>
    <clr-dg-cell>{{ result.wupiLastName }}</clr-dg-cell>
    <clr-dg-cell>{{ result.wupiFirstName }}</clr-dg-cell>
    <clr-dg-cell>{{ result.sponsorName }}</clr-dg-cell>
    <clr-dg-cell>{{ result.grantWorkTag }}</clr-dg-cell>
    <clr-dg-cell>{{ result.costCenterId }}</clr-dg-cell>
    <clr-dg-cell>{{ result.poNumber }}</clr-dg-cell>
    <clr-dg-cell>{{ result.awardYear }}</clr-dg-cell>
    <clr-dg-cell>{{ result.subrecipient }}</clr-dg-cell>
    <clr-dg-cell>{{ result.foreignEntity }}</clr-dg-cell>
    <clr-dg-cell>{{ result.subPILastName }}</clr-dg-cell>
    <clr-dg-cell>{{ this.getStatus(result.status) }}</clr-dg-cell>
    <clr-dg-cell>{{ result.submittedDate | date: 'shortDate' }}</clr-dg-cell>
    <clr-dg-cell>{{ result.returnedDate | date: 'shortDate' }}</clr-dg-cell>
    <clr-dg-cell>{{ result.awardDept }}</clr-dg-cell>
    <clr-dg-cell>{{ result.agreementNumber }}</clr-dg-cell>
    <clr-dg-cell>{{ result.trackingNumber }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer data-test-id="results-amount"
    >{{ searchResults.length === 0 ? 'No' : searchResults.length }} result{{
      searchResults.length === 1 ? '' : 's'
    }}</clr-dg-footer
  >
</clr-datagrid>
