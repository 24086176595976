<div class="form-container">
  <form
    clrForm
    [formGroup]="form"
    style="
        flex-grow: 1;
        overflow-y: auto;
        padding-right: 24px;
      "
  >
    <div class="card">
      <div class="card-header">
        Project Summary
      </div>
      <div class="card-block" [formGroup]="form">
        <div class="clr-row">
          <subs-static-text
            class="clr-col-md"
            controlName="proposalId"
            label="Proposal ID"
            labelLength="6"
          ></subs-static-text>

          <subs-static-text
            class="clr-col-md"
            controlName="subawardId"
            label="Subaward ID"
            labelLength="6"
          ></subs-static-text>

          <subs-static-text
            class="clr-col-md"
            controlName="awardDepartment"
            labelLength="6"
            maxLength="10"
          ></subs-static-text>
        </div>

        <div class="clr-row">
          <subs-static-text
            class="clr-col-md"
            controlName="awardYear"
            labelLength="6"
            maxLength="6"
          ></subs-static-text>

          <subs-static-text
            class="clr-col-md"
            controlName="amendment"
            labelLength="6"
            maxLength="6"
          ></subs-static-text>

          <subs-subaward-status-select
            class="clr-col-4"
            controlName="status"
            labelLength="3"
            [statuses]="activeStatusList"
          >
          </subs-subaward-status-select>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        Department Issuing Subaward
      </div>
      <div class="card-block">
        <div class="clr-row">
          <subs-static-text
            class="clr-col-4"
            label="WashU PI's Dept"
            controlName="issuingDepartment"
            maxLength="10"
          ></subs-static-text>

          <subs-static-text
            class="clr-col-4"
            label="Fund"
            controlName="fund"
            maxLength="7"
          ></subs-static-text>

          <div class="clr-col-4">
            <div class="clr-row">
              <subs-static-text
                class="clr-col-7"
                label="Sub Class"
                labelLength="3"
                controlName="subClass"
              ></subs-static-text>
              <subs-static-text
                class="clr-col-5"
                labelLength="0"
                controlName="issuingFundSubClassBudgetObject"
              ></subs-static-text>
            </div>
          </div>
        </div>
        <div class="clr-row">
          <subs-static-text
            class="clr-col-12"
            label="WashU PI's Dept Name"
            controlName="awardDepartmentName"
          ></subs-static-text>
        </div>

        <div class="clr-row">
          <subs-static-text
            class="clr-col-12"
            label="WashU PI's Name"
            controlName="principalInvestigatorName"
          ></subs-static-text>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        OSRS Only
      </div>
      <div class="card-block">
        <div class="clr-row">
          <subs-static-text
            class="clr-col-6"
            label="PO/CO Doc ID"
            controlName="poCoDocId"
          ></subs-static-text>
          <subs-static-text
            class="clr-col-6"
            label="PO/CO Encumb ID"
            controlName="poCoEncumbId"
          ></subs-static-text>
        </div>
        <div class="clr-row">
          <subs-static-text
            class="clr-col-6"
            label="WU Agreement Number"
            controlName="agreementNumber"
            maxLength="40"
          ></subs-static-text>
          <subs-static-text
            class="clr-col-6"
            label="WU Tracking Number"
            controlName="trackingNumber"
            maxLength="40"
          ></subs-static-text>
        </div>
        <div class="clr-row">
          <subs-static-text
            class="clr-col"
            label="WU Signature Date"
            controlName="signatureDate"
          >
          </subs-static-text>
        </div>
        <div class="clr-row clr-align-items-end">
          <subs-date
            class="clr-col"
            label="Sub Received"
            WashuInformationComponent
            controlName="subReceived"
          >
          </subs-date>
          <div class="clr-col">
            <button class="btn btn-sm" (click)="markReceived()">
              Mark Received
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        COI Information
      </div>
      <div class="card-block">
        <div class="clr-row">
          <p class="clr-col-12 clr-col-md-6">
            Has the Collaborating Institution elected to follow it's own
            compliant FCOI Policy?
          </p>

          <subs-yes-no
            class="clr-col-12 clr-col-md-6"
            controlName="coiPolicy"
            hideLabel
          >
          </subs-yes-no>
        </div>
        <div class="clr-row">
          <subs-date
            class="clr-col"
            label="Disclosure Completion Date"
            controlName="disclosureCompletionDate"
          >
          </subs-date>
          <subs-date
            class="clr-col"
            label="Education Module Completion Date"
            controlName="educationModuleCompletionDate"
            labelLength="10"
          >
          </subs-date>
        </div>
        <div class="clr-row">
          <subs-date
            label="Approval Date"
            class="clr-col"
            controlName="approvalDate"
            [disabled]="isDateEnabled() ? null : true"
          >
          </subs-date>

          <subs-static-text
            class="clr-col"
            label="Last Modified By"
            controlName="coiEmplId"
            labelLength="8"
          ></subs-static-text>
        </div>
        <div class="clr-row clr-align-items-end">
          <div class="clr-col">
            <subs-static-text
              label="Email Sent"
              controlName="coiEmailSent"
            ></subs-static-text>
          </div>
          <div class="clr-col">
            <button
              class="btn btn-sm"
              (click)="fcoiEmailClicked.emit()"
              [attr.disabled]="coiPolicy === false ? null : true"
              data-test-id="fcoi-email-btn"
            >
              FCOI Email
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
