import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { FFATAReportClient } from 'src/app/api.service';

@Component({
  selector: 'subs-ffata-report',
  templateUrl: './ffata-report.component.html',
  providers: [FeedbackService],
})
export class FfataReportComponent implements OnInit {
  submitState = this.feedbackService.submitState;

  searchForm: FormGroup;

  alertSubject = this.feedbackService.alerts;
  years: number[] = [];
  controls = ['month', 'year', 'fiscalYear'];
  constructor(
    private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService,
    private ffataReportClient: FFATAReportClient,
  ) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group(
      {
        month: [{ value: '', disabled: false }],
        year: [{ value: '', disabled: false }],
        fiscalYear: [{ value: '', disabled: false }],
        summaryOnly: [{ value: false, disabled: false }],
      },
      { validators: this.customValidator },
    );
    const y = new Date().getFullYear() + 1; // adding +1 to handle a new FY in the middle of a calendar year
    for (let i = 0; i + 2012 <= y; i++) {
      this.years.push(i + 2012);
    }

    this.onChanges();
  }

  onChanges() {
    this.searchForm.valueChanges.subscribe((val) => {
      this.updateValidationAndState();
    });
  }
  customValidator(formGroup: FormGroup) {
    const month = formGroup.get('month').value;
    const year = formGroup.get('year').value;
    const fiscalYear = formGroup.get('fiscalYear').value;

    if (fiscalYear && (month || year)) {
      return { fiscalYearInvalid: true };
    }

    if ((month && !year) || (!month && year)) {
      return { monthYearInvalid: true };
    }

    if (!fiscalYear && (!month || !year)) {
      return { mandatoryFieldsMissing: true };
    }

    return null; // No errors found
  }

  updateValidationAndState() {
    const monthControl = this.searchForm.get('month');
    const yearControl = this.searchForm.get('year');
    const fiscalYearControl = this.searchForm.get('fiscalYear');

    if (monthControl.value || yearControl.value) {
      fiscalYearControl.disable({ emitEvent: false });
      monthControl.setValidators(Validators.required);
      yearControl.setValidators(Validators.required);
    } else {
      fiscalYearControl.enable({ emitEvent: false });
      monthControl.clearValidators();
      yearControl.clearValidators();
    }

    if (fiscalYearControl.value) {
      monthControl.disable({ emitEvent: false });
      yearControl.disable({ emitEvent: false });
    } else {
      monthControl.enable({ emitEvent: false });
      yearControl.enable({ emitEvent: false });
    }

    monthControl.updateValueAndValidity({ emitEvent: false });
    yearControl.updateValueAndValidity({ emitEvent: false });
    fiscalYearControl.updateValueAndValidity({ emitEvent: false });
  }
  contractReport() {
    this.feedbackService.beginLoading();

    const month =
      this.searchForm.controls.month.value === ''
        ? 0
        : this.searchForm.controls.month.value;
    const year =
      this.searchForm.controls.year.value === ''
        ? 0
        : this.searchForm.controls.year.value;
    const fiscalYear =
      this.searchForm.controls.fiscalYear.value === ''
        ? 0
        : this.searchForm.controls.fiscalYear.value;
    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      if (this.searchForm.controls.summaryOnly.value == true) {
        this.ffataReportClient
          .getContractReportSummary(month, year, fiscalYear)
          .pipe(this.feedbackService.provideFeedback())
          .subscribe((response) => {
            const dataUrl = window.URL.createObjectURL(response.data);
            this.saveFile(dataUrl, 'FFATA Contract Summary Report.csv');
            window.URL.revokeObjectURL(dataUrl);
          });
      } else {
        this.ffataReportClient
          .getContractReport(month, year, fiscalYear)
          .pipe(this.feedbackService.provideFeedback())
          .subscribe((response) => {
            const dataUrl = window.URL.createObjectURL(response.data);
            this.saveFile(dataUrl, 'FFATA Contract Report.csv');
            window.URL.revokeObjectURL(dataUrl);
          });
      }
    }
  }

  grantReport() {
    this.feedbackService.beginLoading();
    const month =
      this.searchForm.controls.month.value === ''
        ? 0
        : this.searchForm.controls.month.value;
    const year =
      this.searchForm.controls.year.value === ''
        ? 0
        : this.searchForm.controls.year.value;
    const fiscalYear =
      this.searchForm.controls.fiscalYear.value === ''
        ? 0
        : this.searchForm.controls.fiscalYear.value;
    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      this.ffataReportClient
        .getGrantReport(month, year, fiscalYear)
        .pipe(this.feedbackService.provideFeedback())
        .subscribe((response) => {
          const dataUrl = window.URL.createObjectURL(response.data);
          this.saveFile(dataUrl, 'FFATA Grant Report.csv');
          window.URL.revokeObjectURL(dataUrl);
        });
    }
  }

  private saveFile(dataUrl: string, fileName: string) {
    const anchor = document.createElement('a');
    anchor.href = dataUrl;
    anchor.download = fileName;
    anchor.click();
  }

  clear() {
    // Reset form to its initial state
    this.searchForm.reset({
      month: '',
      year: '',
      fiscalYear: '',
    });

    // Update the disabled/enabled state of the form controls
    this.updateValidationAndState();

    this.feedbackService.clearAlert();
  }
}
