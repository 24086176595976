<form clrForm [formGroup]="searchForm">
  <div class="clr-row">
    <subs-select class="clr-col-lg-auto" labelLength="2" controlName="month">
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
    </subs-select>

    <subs-select class="clr-col-lg-auto" labelLength="2" controlName="year">
      <option *ngFor="let item of years" value="{{ item }}">{{ item }}</option>
    </subs-select>

    <subs-select
      class="clr-col-lg-auto"
      labelLength="4"
      controlName="fiscalYear"
    >
      <option *ngFor="let item of years" value="{{ item }}">{{ item }}</option>
    </subs-select>
  </div>
  <!-- <div class="clr-row">
    <subs-checkbox
      class="clr-col"
      controlName="summaryOnly"
      labelLength="4"
      label="Summary Only?"
    ></subs-checkbox>
  </div> -->

  <div style="margin-top: 24px">
    <div *ngIf="searchForm.errors?.fiscalYearInvalid">
      <small style="color: red"
        >Fiscal Year cannot be populated if Month or Year is populated.</small
      >
    </div>
    <div *ngIf="searchForm.errors?.monthYearInvalid">
      <small style="color: red"
        >If month is populated, year must also be populated and vice
        versa.</small
      >
    </div>
    <div *ngIf="searchForm.errors?.mandatoryFieldsMissing">
      <small style="color: red">
        Either Month and Year need to be populated or Fiscal Year needs to be
        populated.
      </small>
    </div>
  </div>
  <div>
    <subs-alert [alertBehavior]="alertSubject"> </subs-alert>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="grantReport()"
      data-test-id="search-btn"
      [disabled]="!searchForm.valid"
    >
      Export Grants Report
    </button>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="contractReport()"
      data-test-id="search-btn"
      [disabled]="!searchForm.valid"
    >
      Export Contracts Report
    </button>

    <button class="btn btn-outline" (click)="clear()" data-test-id="clear-btn">
      Clear
    </button>
  </div>
</form>
