import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import * as fs from 'file-saver';
import {
  UntypedFormBuilder,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import {
  UserProfilesClient,
  UserProfileViewModel,
  FilesClient,
  LookupItem,
  UtilityTableKeyEnum,
} from 'src/app/api.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { tap } from 'rxjs/operators';
import { AlertOptions } from 'src/app/alerts/alert-options';
import { UserProfileFactory } from './user-profile-control-factory';
import { DownloadService } from 'src/app/download.service';
import { ExcelExportService } from 'src/app/reports/excel-export.service';
import {
  ClrDatagridComparatorInterface,
  ClrDatagridSortOrder,
  ClrDatagridStringFilterInterface,
} from '@clr/angular';
import { markFormGroupTouched } from 'src/app/form-layout/markFormGroupTouched';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { LookupItemsService } from '../lookup-items.service';
import _ from 'lodash';
import { AuthService } from '../../auth.service';
import { EmployeeLookupComponent } from '../../subawards/edit-project/employee-lookup/employee-lookup.component';

class StringComparator implements ClrDatagridComparatorInterface<any> {
  accessValue = '';
  constructor(private stringToCompare: string) {
    this.accessValue = stringToCompare;
  }
  compare(a: any, b: any) {
    if (a.value[this.accessValue] && b.value[this.accessValue]) {
      return a.value[this.accessValue].localeCompare(b.value[this.accessValue]);
    } else {
      if (a.value[this.accessValue]) {
        return 0;
      }
    }
  }
}

class RoleComparator implements ClrDatagridComparatorInterface<any> {
  accessValue = '';
  constructor(private stringToCompare: string) {
    this.accessValue = stringToCompare;
  }
  compare(a: any, b: any) {
    if (a.value[this.accessValue] && b.value[this.accessValue]) {
      return a.value[this.accessValue].localeCompare(b.value[this.accessValue]);
    } else {
      if (a.value[this.accessValue]) {
        return 0;
      }
    }
  }
}

class IdComparator implements ClrDatagridComparatorInterface<any> {
  compare(a: any, b: any) {
    return a.value['wustlId'] - b.value['wustlId'];
  }
}

class StringFilter implements ClrDatagridStringFilterInterface<any> {
  accessValue = '';
  constructor(private string: string) {
    this.accessValue = string;
  }

  accepts(a: any, search: string): boolean {
    if (a.value[this.accessValue]) {
      return (
        '' + a.value[this.accessValue] === search ||
        a.value[this.accessValue].toString().toLowerCase().indexOf(search) >= 0
      );
    }
  }
}

@Component({
  selector: 'subs-security',
  templateUrl: './security.component.html',
  providers: [FeedbackService],
})
export class SecurityComponent implements OnInit {
  @ViewChild(EmployeeLookupComponent, { static: true })
  employeeLookup!: EmployeeLookupComponent;

  alertOptions: AlertOptions = {
    message: '',
    canBeClosed: true,
    type: 'danger',
  };
  submitState = this.feedbackService.submitState;
  nameComparator = new StringComparator('employeeName');
  roleComparator = new RoleComparator('role');
  descSort = ClrDatagridSortOrder.ASC;
  wustlIdFilter = new StringFilter('wustlId');
  nameFilter = new StringFilter('employeeName');
  emailFilter = new StringFilter('email');
  jobTitleFilter = new StringFilter('jobTitle');
  roleFilter = new StringFilter('role');
  osrsAuthorityAccess = this.authService.hasPermission(
    'OsrsAuthorityGlobalAccess',
  );
  userRoles: LookupItem[] = [];
  result: UserProfileViewModel[];
  safeInputStyle: SafeStyle;

  searchForm = this.fb.group({
    showInactive: [''],
  });

  form = this.fb.group({
    userProfiles: this.fb.array([]),
  });

  get userProfiles(): UntypedFormArray {
    return this.form.get('userProfiles') as UntypedFormArray;
  }

  newUserProfile = this.fb.group({
    id: [0],
    wustlId: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    employeeName: [],
    role: ['', Validators.required],
    email: [],
    jobTitle: [],
    isActive: [],
    dateOfAction: [],
    notes: [],
    signatureFileId: [],
    signatureFileName: [],
  });

  lookupEmployee() {
    this.employeeLookup.lookupEmployee().subscribe((employee) => {
      this.newUserProfile.patchValue({
        wustlId: employee.employeeUniversalId,
        employeeName: `${employee.lastName}, ${employee.firstName}`,
        email: employee.commonEmail,
        jobTitle: employee.jobTitle,
      });
    });
  }

  onAdd() {
    this.newUserProfile.patchValue({ dateOfAction: new Date() });
    this.newUserProfile.patchValue({ isActive: true });

    if (this.newUserProfile.valid) {
      const control = this.controlFactory.createControl(
        this.newUserProfile.value,
      );
      this.newUserProfile.reset();
      this.userProfiles.push(control);
      this.feedbackService.provideFeedback();
    } else {
      markFormGroupTouched(this.newUserProfile);
    }
  }

  onClear() {
    this.newUserProfile.reset();
  }

  ngOnInit() {
    this.lookupItemService
      .lookupItems(UtilityTableKeyEnum.UserRoleLookup)
      .subscribe((value) => {
        this.userRoles = value;
      });
    this.safeInputStyle =
      this.sanitizer.bypassSecurityTrustStyle('margin-top:0px;');
    this.userProfileClient
      .get()
      .pipe(
        tap((result) => {
          this.result = result;
          this.bindUserProfile(this.result.filter((x) => x.isActive));
        }),
      )
      .subscribe();

    this.searchForm.controls.showInactive.valueChanges.subscribe((value) => {
      this.filterSearchResult(value);
    });
  }

  private filterSearchResult(showInactive: boolean) {
    if (showInactive) {
      this.bindUserProfile(this.result.filter((x) => !x.isActive));
    } else {
      this.bindUserProfile(this.result.filter((x) => x.isActive));
    }
  }

  private bindUserProfile(userProfiles: Partial<UserProfileViewModel[]>) {
    this.userProfiles.clear();
    this.controlFactory
      .createMany(userProfiles.length)
      .forEach((control) => this.userProfiles.push(control));
    this.userProfiles.reset(userProfiles, { emitEvent: false });
    this.chRef.detectChanges();
  }

  download(id) {
    for (const c of this.userProfiles.controls) {
      if (c.get('id').value === id) {
        this.downloadService
          .downloadFile(
            c.get('signatureFileId').value,
            c.get('signatureFileName').value,
          )
          .subscribe();
        break;
      }
    }
  }

  save() {
    const value = this.userProfiles.value;
    this.userProfileClient
      .put(value)
      .pipe(this.feedbackService.provideFeedback())
      .subscribe();
  }

  fileUploaded({ fileName, fileId }: { fileName: string; fileId: string }, id) {
    for (const c of this.userProfiles.controls) {
      if (c.get('id').value === id) {
        c.patchValue({
          signatureFileName: fileName,
          signatureFileId: fileId,
          dateOfAction: new Date(),
        });
        break;
      }
    }
  }

  deleteSignature(id) {
    for (const c of this.userProfiles.controls) {
      if (c.get('id').value === id) {
        this.fileClient.delete(c.get('signatureFileId').value).subscribe();
        c.patchValue({
          signatureFileName: '',
          signatureFileId: '',
          dateOfAction: new Date(),
        });
        break;
      }
    }
  }

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private userProfileClient: UserProfilesClient,
    private controlFactory: UserProfileFactory,
    private feedbackService: FeedbackService,
    private downloadService: DownloadService,
    public lookupItemService: LookupItemsService,
    private fileClient: FilesClient,
    private http: HttpClient,
    private chRef: ChangeDetectorRef,
    private excelExportService: ExcelExportService,
    private sanitizer: DomSanitizer,
  ) {}

  public async downloadSecurityRolesSpreadsheet() {
    const response = await this.http
      .get('/assets/SUBS_Security_Access_by_Role.xlsx', {
        responseType: 'arraybuffer',
      })
      .toPromise();

    const blob = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    fs.saveAs(blob, 'SUBS Security Access by Role.xlsx');
  }

  async downloadReport() {
    this.feedbackService.beginLoading();
    this.userProfileClient
      .get()
      .pipe(this.feedbackService.provideFeedback())
      .subscribe(async (val) => await this.generateReport(val));
  }

  private generateReportData(results: Array<UserProfileViewModel>) {
    const data = [];
    results.forEach((e) => {
      data.push([
        e.wustlId,
        e.employeeName,
        e.role,
        e.email,
        e.jobTitle,
        e.isActive === true ? 'Active' : 'Inactive',
        e.dateOfAction ? new Date(e.dateOfAction).toLocaleDateString() : '',
        e.notes,
        e.signatureFileName,
      ]);
    });
    return data;
  }

  async generateReport(results: Array<UserProfileViewModel>) {
    const columns = [
      {
        header: 'WUSTID ID',
        width: 20,
      },
      {
        header: 'Employee Name',
        width: 40,
      },
      {
        header: 'User Role',
        width: 25,
      },
      {
        header: 'Email',
        width: 35,
      },
      {
        header: 'Job Title',
        width: 35,
      },
      {
        header: 'Status',
        width: 10,
      },
      {
        header: 'Date of Action',
        width: 15,
      },
      {
        header: 'Notes',
        width: 40,
      },
      {
        header: 'Signature File Name',
        width: 40,
      },
    ];

    const data = this.generateReportData(results);

    await this.excelExportService.generateExcel(
      'Security Profiles',
      'Security Profiles Export',
      data,
      columns,
    );
  }
}
