import { Component } from '@angular/core';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  SentDistFFATAReportClient,
  SentDistFFATAReportViewModel,
} from 'src/app/api.service';
import { ExcelExportService } from '../excel-export.service';
import * as Excel from 'exceljs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'subs-sent-dist-ffata-report',
  templateUrl: './sent-dist-ffata-report.component.html',
  providers: [FeedbackService, DatePipe],
})
export class SentDistFfataReportComponent {
  alertSubject$ = this.feedbackService.alerts;
  submitState = this.feedbackService.submitState;
  assignedGAOptions: Array<string> = [];

  columnsDist = [
    {
      header: 'Subaward #',
      width: 39,
    },
    {
      header: 'OSRS GA',
      width: 12,
    },
    {
      header: 'WashU PI',
      width: 22,
    },
    {
      header: 'Date Submitted to OSRS',
      width: 33,
    },
    {
      header: 'Date Opened by OSRS',
      width: 33,
    },
    {
      header: 'Pending Approval ',
      width: 33,
    },
    {
      header: 'Date Sent to Sub',
      width: 33,
    },
    {
      header: 'Days in Negotiation',
      width: 33,
    },
    {
      header: 'Date Distributed to Sub',
      width: 33,
    },
    {
      header: 'Name of Subrecipient',
      width: 62,
    },
    {
      header: 'Pending OSRS Review',
      width: 27,
    },
    {
      header: 'OSRS Processing Time',
      width: 34,
    },
    {
      header: 'Sent to Sub',
      width: 27,
    },
    {
      header: 'Negotiation Count',
      width: 27,
    },
    {
      header: 'Distributed to Sub',
      width: 20,
    },
  ];

  columnsSent = [
    {
      header: 'Subaward #',
      width: 39,
    },
    {
      header: 'OSRS GA',
      width: 12,
    },
    {
      header: 'WashU PI',
      width: 22,
    },
    {
      header: 'Date Submitted to OSRS',
      width: 33,
    },
    {
      header: 'Date Opened by OSRS',
      width: 33,
    },
    {
      header: 'Pending Approval ',
      width: 33,
    },
    {
      header: 'Date Sent to Sub',
      width: 33,
    },
    {
      header: 'Name of Subrecipient',
      width: 62,
    },
    {
      header: 'Pending OSRS Review',
      width: 27,
    },
    {
      header: 'OSRS Processing Time',
      width: 34,
    },
    {
      header: 'Sent to Sub',
      width: 27,
    },
  ];

  columnsSummaryOnly = [
    {
      header: '',
      width: 39,
    },
    {
      header: '',
      width: 12,
    },
    {
      header: '',
      width: 22,
    },
    {
      header: '',
      width: 33,
    },
    {
      header: '',
      width: 33,
    },
    {
      header: '',
      width: 33,
    },
    {
      header: '',
      width: 33,
    },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService,
    private reportService: SentDistFFATAReportClient,
    private excelExportService: ExcelExportService,
    private datePipe: DatePipe,
  ) {}

  searchForm = this.fb.group({
    dateFrom: ['', Validators.required],
    dateThru: ['', Validators.required],
    assignedGA: [''],
    summaryOnly: [false],
    reportType: ['sent'],
  });

  clear() {
    this.searchForm.controls.dateFrom.setValue('');
    this.searchForm.controls.dateThru.setValue('');
    this.searchForm.controls.assignedGA.setValue('');
    this.searchForm.controls.summaryOnly.setValue(false);
    this.searchForm.controls.reportType.setValue('sent');
    this.feedbackService.clearAlert();
  }

  search() {
    this.feedbackService.beginLoading();

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      this.reportService
        .get(
          new Date(this.searchForm.controls.dateFrom.value),
          new Date(this.searchForm.controls.dateThru.value),
          this.searchForm.controls.assignedGA.value,
          this.searchForm.controls.reportType.value,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(
          async (val) =>
            await this.generateReport(
              val,
              this.searchForm.controls.summaryOnly.value,
              this.searchForm.controls.reportType.value,
            ),
        );
    }
  }

  async generateReport(
    results: Array<SentDistFFATAReportViewModel>,
    isSummaryOnly: boolean,
    reportType: string,
  ) {
    if (results.length === 0) {
      this.feedbackService.alert('There are no results for your search');
      return;
    }

    var columns = reportType === 'sent' ? this.columnsSent : this.columnsDist;
    columns = isSummaryOnly ? this.columnsSummaryOnly : columns;

    const data = this.generateReportData(results, isSummaryOnly, reportType);
    const totalsObj = this.generateTotals(results);

    await this.excelExportService.generateExcel(
      'SUBS Executed Report',
      'SUBS Executed Report',
      data,
      columns,
      {
        value: `Date Range ${this.searchForm.controls.dateFrom.value} - ${
          this.searchForm.controls.dateThru.value
        },
        Report Type: ${
          this.searchForm.controls.reportType.value === 'sent'
            ? 'Sent'
            : 'Distributed'
        },
        OSRS GA: ${this.searchForm.controls.assignedGA.value}`,
      },
      this.addSummary,
      totalsObj,
      isSummaryOnly,
    );
  }

  private generateReportData(
    results: Array<SentDistFFATAReportViewModel>,
    isSummaryOnly: boolean,
    reportType: string,
  ) {
    const data = [];
    if (!isSummaryOnly) {
      if (reportType === 'dist') {
        results.forEach((e) => {
          data.push([
            e.subawardNumber,
            e.assignedGA,
            e.washUPI,
            e.dateSubmittedToOSRS
              ? new Date(e.dateSubmittedToOSRS).toLocaleDateString()
              : '',
            e.dateOpenedByOSRS
              ? new Date(e.dateOpenedByOSRS).toLocaleDateString()
              : '',
            e.pendingApproval
              ? new Date(e.pendingApproval).toLocaleDateString()
              : this.setMissingPendingApprovalDate(e.dateSentToSub),
            e.dateSentToSub ? this.formatSentToSubDate(e.dateSentToSub) : '',
            e.daysInNegotiation,
            e.dateDistributedToSub
              ? this.formatDistributedToSubDate(e.dateDistributedToSub)
              : '',
            e.nameOfSubrecipient,
            e.pendingOSRSReview,
            e.osrsProcessingTime,
            e.sentToSub,
            e.hasNegotiationAuditRecords,
            e.distributedToSub,
          ]);
        });
      } else {
        // Report Type: Sent
        results.forEach((e) => {
          data.push([
            e.subawardNumber,
            e.assignedGA,
            e.washUPI,
            e.dateSubmittedToOSRS
              ? new Date(e.dateSubmittedToOSRS).toLocaleDateString()
              : '',
            e.dateOpenedByOSRS
              ? new Date(e.dateOpenedByOSRS).toLocaleDateString()
              : '',
            e.pendingApproval
              ? new Date(e.pendingApproval).toLocaleDateString()
              : this.setMissingPendingApprovalDate(e.dateSentToSub),
            e.dateSentToSub ? this.formatSentToSubDate(e.dateSentToSub) : '',
            e.nameOfSubrecipient,
            e.pendingOSRSReview,
            e.osrsProcessingTime,
            e.sentToSub,
          ]);
        });
      }
    }
    return data;
  }

  private formatSentToSubDate(dateSentToSub: Date) {
    return this.datePipe.transform(dateSentToSub, 'MM/dd/yyyy hh:mm:ss aaa');
  }

  private formatDistributedToSubDate(dateDistributedToSub: Date) {
    return this.datePipe.transform(
      dateDistributedToSub,
      'MM/dd/yyyy hh:mm:ss aaa',
    );
  }

  private setMissingPendingApprovalDate(dateSentToSub: Date) {
    return dateSentToSub ? new Date(dateSentToSub).toLocaleDateString() : '';
  }

  private generateTotals(results: Array<SentDistFFATAReportViewModel>) {
    const invalidSubs = results.filter((a) => !a.subawardNumber);

    if (invalidSubs && invalidSubs.length > 0) {
      this.feedbackService.alert(
        `SubAwards found with missing SubAward Numbers. Fund Numbers: ${invalidSubs.map(
          (i) => i.fundNumber,
        )}`,
      );
      return;
    }

    const summaryMOD = results.filter((a) => a.subawardNumber.includes('MOD'));
    var summaryMODDays: SentDistFFATAReportViewModel;
    var totalMODDays = 0;
    if (summaryMOD.length === 0) {
      summaryMODDays = {
        pendingOSRSReview: 0,
        osrsProcessingTime: 0,
        distributedToSub: 0,
        itemsInNegotiation: 0,
        daysInNegotiation: 0,
        sentToSub: 0,
      };
    } else {
      summaryMODDays = summaryMOD.reduce((a, b) => {
        return {
          pendingOSRSReview: a.pendingOSRSReview + b.pendingOSRSReview,
          osrsProcessingTime: a.osrsProcessingTime + b.osrsProcessingTime,
          distributedToSub: a.distributedToSub + b.distributedToSub,
          itemsInNegotiation: a.itemsInNegotiation + b.itemsInNegotiation,
          daysInNegotiation: a.daysInNegotiation + b.daysInNegotiation,
          sentToSub: a.sentToSub + b.sentToSub,
        };
      });

      totalMODDays = summaryMOD.reduce((a, b) => {
        return (
          a +
          b.distributedToSub +
          b.osrsProcessingTime +
          b.pendingOSRSReview +
          b.itemsInNegotiation +
          b.daysInNegotiation +
          b.sentToSub
        );
      }, 0);
    }

    const summaryNew = results.filter((a) => !a.subawardNumber.includes('MOD'));
    var summaryNewDays: SentDistFFATAReportViewModel;
    var totalNewDays = 0;
    if (summaryNew.length === 0) {
      summaryNewDays = {
        pendingOSRSReview: 0,
        osrsProcessingTime: 0,
        distributedToSub: 0,
        itemsInNegotiation: 0,
        daysInNegotiation: 0,
        sentToSub: 0,
      };
    } else {
      summaryNewDays = summaryNew.reduce((a, b) => {
        return {
          pendingOSRSReview: a.pendingOSRSReview + b.pendingOSRSReview,
          osrsProcessingTime: a.osrsProcessingTime + b.osrsProcessingTime,
          distributedToSub: a.distributedToSub + b.distributedToSub,
          itemsInNegotiation: a.itemsInNegotiation + b.itemsInNegotiation,
          daysInNegotiation: a.daysInNegotiation + b.daysInNegotiation,
          sentToSub: a.sentToSub + b.sentToSub,
        };
      });

      totalNewDays = summaryNew.reduce((a, b) => {
        return (
          a +
          b.distributedToSub +
          b.osrsProcessingTime +
          b.pendingOSRSReview +
          b.itemsInNegotiation +
          b.daysInNegotiation +
          b.sentToSub
        );
      }, 0);
    }

    var newSubs: SummaryData = {
      avgPendingOSRSReview:
        summaryNew.length === 0
          ? 0
          : summaryNewDays.pendingOSRSReview / summaryNew.length,
      avgOsrsProcessingTime:
        summaryNew.length === 0
          ? 0
          : summaryNewDays.osrsProcessingTime / summaryNew.length,
      avgSentToSub:
        summaryNew.length === 0
          ? 0
          : summaryNewDays.sentToSub / summaryNew.length,
      avgDistributedToSub:
        summaryNew.length === 0
          ? 0
          : summaryNewDays.distributedToSub / summaryNew.length,
      percPendingOSRSReview:
        totalNewDays === 0
          ? 0
          : summaryNewDays.pendingOSRSReview / totalNewDays,
      percOsrsProcessingTime:
        totalNewDays === 0
          ? 0
          : summaryNewDays.osrsProcessingTime / totalNewDays,
      percSentToSub:
        totalNewDays === 0 ? 0 : summaryNewDays.sentToSub / totalNewDays,
      percDistributedToSub:
        totalNewDays === 0 ? 0 : summaryNewDays.distributedToSub / totalNewDays,
      itemsInNegotiation:
        summaryNewDays.itemsInNegotiation === 0
          ? 0
          : summaryNewDays.itemsInNegotiation,
      percInNegotiation:
        totalNewDays === 0
          ? 0
          : summaryNewDays.daysInNegotiation / totalNewDays,
      countOfNegotiatedItems: summaryNew.map(
        (x) => x.countOfNewNegotiatedItems,
      )[0],
      avgDaysInNegotiation:
        summaryNew.length === 0
          ? 0
          : summaryNewDays.daysInNegotiation / summaryNew.length,
      percItemNegotiated:
        summaryNew.map((x) => x.countOfNewNegotiatedItems)[0] / results.length,
      total: summaryNew.length,
    };

    var mod: SummaryData = {
      avgPendingOSRSReview:
        summaryMOD.length === 0
          ? 0
          : summaryMODDays.pendingOSRSReview / summaryMOD.length,
      avgOsrsProcessingTime:
        summaryMOD.length === 0
          ? 0
          : summaryMODDays.osrsProcessingTime / summaryMOD.length,
      avgSentToSub:
        summaryMOD.length === 0
          ? 0
          : summaryMODDays.sentToSub / summaryMOD.length,
      avgDistributedToSub:
        summaryMOD.length === 0
          ? 0
          : summaryMODDays.distributedToSub / summaryMOD.length,
      percPendingOSRSReview:
        totalMODDays === 0
          ? 0
          : summaryMODDays.pendingOSRSReview / totalMODDays,
      percOsrsProcessingTime:
        totalMODDays === 0
          ? 0
          : summaryMODDays.osrsProcessingTime / totalMODDays,
      percSentToSub:
        totalMODDays === 0 ? 0 : summaryMODDays.sentToSub / totalMODDays,
      percDistributedToSub:
        totalMODDays === 0 ? 0 : summaryMODDays.distributedToSub / totalMODDays,
      itemsInNegotiation:
        summaryMODDays.itemsInNegotiation === 0
          ? 0
          : summaryMODDays.itemsInNegotiation,
      percInNegotiation:
        totalMODDays === 0
          ? 0
          : summaryMODDays.daysInNegotiation / totalMODDays,
      countOfNegotiatedItems: summaryMOD.map(
        (x) => x.countOfModNegotiatedItems,
      )[0],
      avgDaysInNegotiation:
        summaryMOD.length === 0
          ? 0
          : summaryMODDays.daysInNegotiation / summaryMOD.length,
      percItemNegotiated:
        summaryMOD.map((x) => x.countOfModNegotiatedItems)[0] / results.length,
      total: summaryMOD.length,
    };

    var total: SummaryData = {
      avgPendingOSRSReview:
        (summaryMODDays.pendingOSRSReview + summaryNewDays.pendingOSRSReview) /
        results.length,
      avgOsrsProcessingTime:
        (summaryMODDays.osrsProcessingTime +
          summaryNewDays.osrsProcessingTime) /
        results.length,
      avgSentToSub:
        (summaryMODDays.sentToSub + summaryNewDays.sentToSub) / results.length,
      avgDistributedToSub:
        (summaryMODDays.distributedToSub + summaryNewDays.distributedToSub) /
        results.length,
      percPendingOSRSReview:
        (summaryMODDays.pendingOSRSReview + summaryNewDays.pendingOSRSReview) /
        (totalMODDays + totalNewDays),
      percOsrsProcessingTime:
        (summaryMODDays.osrsProcessingTime +
          summaryNewDays.osrsProcessingTime) /
        (totalMODDays + totalNewDays),
      percSentToSub:
        (summaryMODDays.sentToSub + summaryNewDays.sentToSub) /
        (totalMODDays + totalNewDays),
      percDistributedToSub:
        (summaryMODDays.distributedToSub + summaryNewDays.distributedToSub) /
        (totalMODDays + totalNewDays),
      itemsInNegotiation:
        summaryNewDays.itemsInNegotiation + summaryMODDays.itemsInNegotiation,
      percInNegotiation:
        (summaryMODDays.daysInNegotiation + summaryNewDays.daysInNegotiation) /
        (totalMODDays + totalNewDays),
      countOfNegotiatedItems:
        summaryMOD.map((x) => x.countOfNewNegotiatedItems)[0] +
        summaryMOD.map((x) => x.countOfModNegotiatedItems)[0],
      countOfDistributedItems: results.map((x) => x.countOfDistrubutedItems)[0],
      total: results.length,
    };

    return [newSubs, mod, total];
  }

  private addSummary(
    worksheet: Excel.Worksheet,
    totalsObj: SummaryData[],
    isSummaryOnly: boolean,
    isSentReportType: boolean,
  ) {
    worksheet.addRow([]);
    //#region Summary Header Row
    const totalRow = worksheet.addRow([
      ,
      ,
      'Pending OSRS Review',
      'OSRS ProcessingTime',
      'Sent to Sub',
      'Negottiated Items',
      'Distributed to Sub',
    ]);
    totalRow.font = {
      size: 12,
      bold: true,
    };
    totalRow.eachCell((cell) => {
      cell.alignment = { vertical: 'top' };
      cell.border = {
        top: {
          style: 'thin',
        },
        left: {
          style: 'thin',
        },
        bottom: {
          style: 'thin',
        },
        right: {
          style: 'thin',
        },
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
      };
    });
    //#endregion

    //#region Summary Data Rows
    totalsObj.forEach((summary, index) => {
      var secondRow = worksheet.addRow([
        index === 0
          ? 'NEW AVERAGE # DAYS --'
          : index === 1
            ? 'MOD AVERAGE # DAYS --'
            : 'TOTAL AVERAGE # DAYS--',

        Math.round(summary.avgPendingOSRSReview),
        Math.round(summary.avgOsrsProcessingTime),
        Math.round(summary.avgSentToSub),
        summary.itemsInNegotiation,
        Math.round(summary.avgDistributedToSub),
      ]);

      secondRow.getCell(1).font = {
        size: 14,
        bold: true,
      };

      secondRow.getCell(6).border = {
        right: {
          style: 'thin',
        },
      };

      var thirdRow = worksheet.addRow([
        '% of time to complete each process step--',
        Math.round(summary.percPendingOSRSReview * 100) + '%',
        Math.round(summary.percOsrsProcessingTime * 100) + '%',
        Math.round(summary.percSentToSub * 100) + '%',
        Math.round(summary.percInNegotiation * 100) + '%',
        Math.round(summary.percDistributedToSub * 100) + '%',
      ]);
      thirdRow.getCell(1).font = {
        bold: true,
      };
      thirdRow.getCell(6).border = {
        right: {
          style: 'thin',
        },
      };
      var fourthRow = worksheet.addRow([, , , , , ,]);

      worksheet.mergeCells(fourthRow.number, 1, fourthRow.number, 6);
      fourthRow.getCell(1).alignment = { horizontal: 'right' };
      fourthRow.getCell(1).font = {
        bold: true,
      };
      fourthRow.getCell(1).value =
        `Total ${index === 0 ? 'New ' : ''}Subs ${
          index === 1 ? 'Mods ' : ''
        }Executed this period: ` +
        ' ' +
        summary.total;
      fourthRow.eachCell((cell) => {
        cell.border = {
          top: {
            style: 'thin',
          },
          left: {
            style: 'thin',
          },
          bottom: {
            style: 'thin',
          },
          right: {
            style: 'thin',
          },
        };
      });
    });
    // #endregion
    if (isSummaryOnly !== true && isSentReportType === false) {
      //#region Negotiation And Distributed Rows
      var negotiatedCountRow = worksheet.addRow([
        'Count of negotiated Items',
        totalsObj[2].countOfNegotiatedItems,
      ]);
      negotiatedCountRow.getCell(1).font = {
        size: 14,
        bold: true,
      };
      negotiatedCountRow.eachCell((cell) => {
        cell.border = {
          top: {
            style: 'thin',
          },
          left: {
            style: 'thin',
          },
          bottom: {
            style: 'thin',
          },
          right: {
            style: 'thin',
          },
        };
      });

      var distrubutedCountRow = worksheet.addRow([
        'Count of distributed Items',
        totalsObj[2].countOfDistributedItems,
        ,
      ]);
      distrubutedCountRow.getCell(1).font = {
        size: 14,
        bold: true,
      };
      distrubutedCountRow.eachCell((cell) => {
        cell.border = {
          top: {
            style: 'thin',
          },
          left: {
            style: 'thin',
          },
          bottom: {
            style: 'thin',
          },
          right: {
            style: 'thin',
          },
        };
      });
      //#endregion

      //#region Negotiation Summary for Distrubuted report
      worksheet.addRow([]);
      //#region Negotiation Summary Header Row
      const negotiationSummaryHeaders = worksheet.addRow([
        ,
        ,
        'Total Negotiation Items',
        'Days in Negotiation Status',
        // 'Days between Negotiatio Status to Distributed',
        'Percentage of items negotiated out of total distributions',
      ]);
      negotiationSummaryHeaders.font = {
        size: 12,
        bold: true,
      };
      negotiationSummaryHeaders.eachCell((cell) => {
        cell.alignment = { vertical: 'top' };
        cell.border = {
          top: {
            style: 'thin',
          },
          left: {
            style: 'thin',
          },
          bottom: {
            style: 'thin',
          },
          right: {
            style: 'thin',
          },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D3D3D3' },
        };
      });
      //#endregion

      //#region Negotiation Summary Data Rows
      var negotiationtotalsObj = [totalsObj[0], totalsObj[1]];
      negotiationtotalsObj.forEach((summary, index) => {
        var newNegotiationsDataRow = worksheet.addRow([
          index === 0 ? 'NEW' : 'MOD',
          summary.countOfNegotiatedItems,
          Math.round(summary.avgDaysInNegotiation),
          // 'Days between Negotiatio Status to Distributed',
          Math.round(summary.percItemNegotiated * 100) + '%',
        ]);

        newNegotiationsDataRow.getCell(1).font = {
          size: 14,
          bold: true,
        };

        newNegotiationsDataRow.getCell(6).border = {
          right: {
            style: 'thin',
          },
        };
      });
      //#endregion

      //#endregion
    }
  }
}

interface SummaryData {
  avgPendingOSRSReview?: number | undefined;
  avgOsrsProcessingTime?: number | undefined;
  avgSentToSub?: number | undefined;
  avgDistributedToSub?: number | undefined;
  percPendingOSRSReview?: number | undefined;
  percOsrsProcessingTime?: number | undefined;
  percSentToSub?: number | undefined;
  percDistributedToSub?: number | undefined;
  total?: number | undefined;
  itemsInNegotiation?: number | undefined;
  percInNegotiation?: number | undefined;
  countOfNegotiatedItems?: number | undefined;
  countOfDistributedItems?: number | undefined;
  avgDaysInNegotiation?: number | undefined;
  percItemNegotiated?: number | undefined;
}
