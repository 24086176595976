import { DatePipe, Location } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router,
} from '@angular/router';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import { cloneDeep, isEqual } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import {
  API_BASE_URL,
  EmailsClient,
  FinancialRiskEmail,
  GetProject as Project,
  Institution,
  InstitutionContact,
  InstitutionFinancialContact,
  InstitutionsClient,
  ProjectsClient,
  Subaward,
  SubawardContact,
  SubawardsClient,
  ReturnedToDAEmail,
  ReturnCodesClient,
  ReturnCode,
  NotApprovedToGAEmail,
  UserProfilesClient,
  ManageSubawardResponse,
  Warning,
  InstitutionOfficerVm,
  SubrecipientStatusVm,
} from 'src/app/api.service';
import { AuthService } from 'src/app/auth.service';
import { DownloadService } from 'src/app/download.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';

import { AlertOptions } from '../../alerts/alert-options';
import { DateConvert } from '../../converters/date-convert/date-convert';
import { markFormGroupTouched } from '../../form-layout/markFormGroupTouched';
import { MainLayoutService } from '../../main-layout/main-layout.service';
import { DateValidator } from '../../validators/date-validator/date-validator';
import { AdditionalDocumentControlFactory } from '../additional-document/additional-document-control-factory';
import { ContractAttachmentControlFactory } from '../subaward-attachments/contract-attachment-control-factory';
import { CollaboratorInstitutionSearchService } from '../subaward-details/collaborator/collaborator-search-modal/collaborator-institution-search.service';
import { MonitoringQuestionControlFactory } from '../subaward-details/subrecipient-monitoring/monitoring-question-control-factory';
import { SubawardsService } from '../subawards.service';
import { RequiredItemsChecklistService } from './required-items-checklist.service';
import { SubawardExcelService } from './subaward-excel-service/subaward-excel.service';
import { FeatureService } from '../../utilities/feature.service';
import { isNumeric } from 'rxjs/internal-compatibility';
import { SubStatusService } from '../../utilities/subrecipient-status/sub-status.service';

interface RequiredControls {
  controlName: string | undefined;
  validators: ValidatorFn[] | undefined;
}

@Component({
  selector: 'subs-subaward',
  templateUrl: './subaward.component.html',
  styleUrls: ['./subaward.component.scss'],
  providers: [DatePipe, FeedbackService],
})
export class SubawardComponent implements OnInit {
  inactiveStatus = false;
  inactiveStatusList = ['CL', 'IA', 'VD'];
  activeStatusList: SubrecipientStatusVm[] = [];

  activeTab = {
    details: true,
    contacts: false,
    comments: false,
    ffata: false,
    fcoi: false,
    additionalDocuments: false,
    subAttachments: false,
    auditHistory: false,
  };

  constructor(
    private fb: UntypedFormBuilder,
    private projectsClient: ProjectsClient,
    private subawardsClient: SubawardsClient,
    private subawardsService: SubawardsService,
    private subStatusService: SubStatusService,
    private institutionsClient: InstitutionsClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private additionalDocumentControlFactory: AdditionalDocumentControlFactory,
    private contractAttachmentControlFactory: ContractAttachmentControlFactory,
    private monitoringQuestionContolFactory: MonitoringQuestionControlFactory,
    private institutionSearchService: CollaboratorInstitutionSearchService,
    private datePipe: DatePipe,
    private checklistService: RequiredItemsChecklistService,
    private subawardExcelService: SubawardExcelService,
    private feedbackService: FeedbackService,
    private emailsClient: EmailsClient,
    private authService: AuthService,
    private mainLayoutService: MainLayoutService,
    private featureService: FeatureService,
    private downloadService: DownloadService,
    private returnCodesClient: ReturnCodesClient,
    private userProfileClient: UserProfilesClient,
    private cdr: ChangeDetectorRef,
    @Inject(API_BASE_URL) private baseUrl: string,
  ) {
    this.activeTab.details = true;
    router.events.forEach((event) => {
      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.mainLayoutService.toggleSubawardSidebar(false);
      }
    });
    this.institutionSearchService.select(null);
    this.saveConfirmationForm.controls.confirmation.valueChanges.subscribe(
      (result) => {
        this.saveConfirmationSubject.next(result);
      },
    );
    this.subawardForm.controls.subrecipientInformation
      .get('budget.directCost')
      .valueChanges.subscribe((result) => {
        const directCost = result ? Number(result) : 0;
        const indirectCostValue =
          this.subawardForm.controls.subrecipientInformation.get(
            'budget.indirectCost',
          ).value;
        const indirectCost = indirectCostValue ? Number(indirectCostValue) : 0;
        this.setTotalCost(directCost + indirectCost);
      });
    this.subawardForm.controls.subrecipientInformation
      .get('budget.indirectCost')
      .valueChanges.subscribe((result) => {
        const indirectCost = result ? Number(result) : 0;
        const directCostValue =
          this.subawardForm.controls.subrecipientInformation.get(
            'budget.directCost',
          ).value;
        const directCost = directCostValue ? Number(directCostValue) : 0;
        this.setTotalCost(directCost + indirectCost);
      });

    this.checklistService.subscribeChecklist(this.subawardForm, this.checklist);

    this.subawardForm.statusChanges.subscribe((val) =>
      this.onFormValidation(val),
    );
  }

  get additionalDocuments(): UntypedFormArray {
    return this.subawardForm.get('additionalDocuments') as UntypedFormArray;
  }

  get contractAttachments(): UntypedFormArray {
    return this.subawardForm.get('contractAttachments') as UntypedFormArray;
  }

  get subrecipientMonitoringQuestions(): UntypedFormArray {
    return this.subawardForm.get(
      'subrecipientMonitoringQuestions',
    ) as UntypedFormArray;
  }

  get emailResult$(): Observable<string> {
    return this.emailResult.asObservable();
  }

  get modTypeSelection(): string {
    return this.subawardForm.get('modType').value;
  }
  feaFileUploaded: boolean;
  updatedInstitution: Institution | null;
  redirectToProjectSummary = false;
  userRole: string;
  userEmail: string;
  previousStatus: string;
  previousPoCoEncumbId: string;
  previousSupplierContractId: string;
  disableButtons = new BehaviorSubject(true);
  disableSubmitToOsrs = new BehaviorSubject(true);
  checkListCompleted = false;
  isCloneAction = false;
  showNoAttachmentsModal = false;
  showFullAgreementProgress = false;
  generateAgreementStatusMessage = new BehaviorSubject<string>('');
  showGenerateAgreementError = false;
  showAgreementSignedModal = false;
  showDMSPModal = false;
  dmspFile = false;
  showSupplierContractIdModal = false;
  invalidSupplierContractId = false;
  supplierContractIdWarningMessage: Warning[] = [];
  showGrantWorkTagModal = false;
  validatingGrantWorkTag = false;
  grantWorkTagWarningMessage: Warning[] = [];
  showSignatureNotFoundModal = new BehaviorSubject<boolean>(false);
  showDuplicatedBookmarkModal = new BehaviorSubject<boolean>(false);
  duplicatedBookmarkMessage = '';
  showSignedSuccessModal = new BehaviorSubject(false);
  showConfirmationExcelImport = false;
  excelImportSubaward: Subaward;
  returnCodes: ReturnCode[];
  showScopeOfWorkNotAnsweredModal = false;
  showFundChangedNotAnsweredModal = false;
  showDescriptionofActionModal = false;
  showMonitoringQuestionsModal = false;
  showMonitoringQuestionsReminderModal = false;
  monitoringQuestionsReminderModalShown = false;
  showCongressionalDistrictModal = false;
  showRouteForSignatureClickedModal = false;
  showPsoStatusChangeModal = false;
  showIpStatusChangeModal = false;
  showSubAwardSavedModal = false;
  showChangesToCurrentYearModal = false;
  restrictedStatuses: string[] = [];
  isActiveFeatureCostCenter = false;
  fixedRate = false;

  requiredControlsBudgetForm: RequiredControls[] = [
    { controlName: 'indirectCostBaseMethodDesc', validators: [] },
  ];

  private msToDelay = 4000;
  private msToDelayEmailConfirmationMessage = 2000;

  emailRecipient: string;
  userProfileList: any;
  previousFormGroupValue: any;
  showUnsavedChangesModal: boolean;
  canSubmitToOsrsModal: boolean;
  showSubmitToOsrsModal: boolean;
  showEmailConfirmation = false;
  showCollaboratorInstitutionAlert = false;
  private emailResult: BehaviorSubject<string> = new BehaviorSubject<string>(
    'Sending...',
  );
  saveConfirmationSubject = new Subject<boolean>();
  saveConfirmationForm = this.fb.group({
    confirmation: [],
  });

  subawardStatus: string;
  projectTitle: string;
  project: Project;
  questionIsVisible = false;
  isModifiedAgreement = false;
  formLevelAlerts$ = new BehaviorSubject<AlertOptions>(null);
  alertSubject$ = this.feedbackService.alerts;
  collaboratorInstituteAlertOptions: Observable<AlertOptions> = new Observable(
    (s) =>
      s.next({
        message:
          'A Collaborating Institution must be selected or when adding a new institution, ' +
          'the Institution Name needs to be entered and saved with an Institution ID assigned.',
        canBeClosed: true,
        type: 'danger',
      }),
  );
  submitState$ = this.feedbackService.submitState;

  checklist = this.fb.group({
    noticeOfAward: [],
    contactInfo: [],
    collaboratorInstitutionalInfo: [],
    dataMgmtSharingPlan: [],
    placeOfPerformance: [],
    scopeOfWorkAttached: [],
    budget: [],
    account: [],
    fAndARateAgreementAttached: [],
    riskCategory: [],
    humanSubjects: [],
    animalSubjects: [],
  });

  washUInformation = this.fb.group({
    projectId: [],
    subawardId: [],
    proposalId: [],
    awardDepartment: [],
    awardNumber: [],
    awardYear: [],
    amendment: [
      '',
      [
        Validators.max(2_147_483_647),
        Validators.min(0),
        Validators.pattern('\\d*'),
      ],
    ],
    status: [],
    sponsorName: [],
    originatingSponsor: [],
    wuPrincipalInvestigator: [],
    awardDepartmentName: [],
    issuingDepartment: [],
    fund: [],
    subClass: [],
    issuingFundSubClassBudgetObject: [],
    departmentName: [],
    grantWorkTag: [],
    costCenterId: [],
    costCenterName: [],
    principalInvestigatorName: [],
    poCoDocId: [],
    poCoEncumbId: [],
    agreementNumber: [],
    trackingNumber: [],
    signatureDate: [],
    subReceived: [],
    disclosureCompletionDate: [],
    educationModuleCompletionDate: [],
    approvalDate: [],
    coiPolicy: [],
    coiPolicyNA: [],
    coiEmplId: [],
    coiEmailSent: [],
    debarment: [],
    exportControl: [],
    debarmentComments: [],
    exportControlComments: [],
    subcontractType: [],
    farContract: [],
    ffataReportable: [],
    foreignAccessClause: [],
    otherTransactionalAgreement: [],
    assignedGA: [],
    previousAssignedGA: [],
    previousAssignedGAFirstName: [],
    previousAssignedGALastName: [],
    supplierContractId: [],
  });

  contactForm = this.fb.group({
    subrecipientId: [],
    centralOfficeEmail: [],
    departmentalAdmin: this.createContactGroup(),
    principalInvestigator: this.createContactGroup(),
    signatory: this.createContactGroup(),
    institutionalAdmin: this.createContactGroup(),
    institutionalFinancial: this.createFinanceContactGroup(),
    washUPrincipalInvestigator: this.createContactGroup(),
    washUDepartmentAdmin: this.createContactGroup(),
  });

  commentsForm = this.fb.group({
    commentHistory: [],
  });

  fullyExecutedAgreementForm = this.fb.group({
    fileName: [],
    fileId: [],
    subrecipientId: [],
    dateUploaded: [],
  });

  subawardForm = this.fb.group({
    id: [],
    washUInformation: this.washUInformation,
    subrecipientInformation: this.createCollaboratorInformationGroup(),
    contact: this.contactForm,
    comments: [],
    auditLogs: [],
    institutionOfficers: [],
    ffataDescription: [],
    approved: [],
    internalDistributionSent: [],
    unilateralModSent: [],
    poNotificationSent: [],
    additionalDocuments: this.fb.array([]),
    fullyExecutedAgreement: this.fullyExecutedAgreementForm,
    contractAttachments: this.fb.array([]),
    modType: [],
    subrecipientMonitoringQuestions: this.fb.array([]),
  });

  private subawardId: number;

  // these validators will be appended dynamically when conditional validation is implemented
  // (example: when the country selection requires the related fields (state, zip, etc.) to be populated)
  // see SetValidatorsBasedOnCountry()
  private stateValidators = [Validators.maxLength(2)];
  private zipValidators = [Validators.maxLength(30)];

  onFormValidation(validity: string) {
    switch (validity) {
      case 'VALID':
        this.feedbackService.clearAlert();
        break;
      case 'INVALID':
        // covered by individual form field validation
        break;
    }
  }

  private setTotalCost(totalCosts) {
    this.subawardForm.controls.subrecipientInformation
      .get('budget.totalCosts')
      .patchValue(Math.round((totalCosts + Number.EPSILON) * 100) / 100);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (
      this.viewOnlyUser() ||
      isEqual(this.subawardForm.value, this.previousFormGroupValue)
    ) {
      return true;
    }

    this.showUnsavedChangesModal = true;

    return this.saveConfirmationSubject;
  }

  confirmSave() {
    this.save();
    this.showUnsavedChangesModal = false;
  }

  discardChanges() {
    this.saveConfirmationForm.controls.confirmation.setValue(true);
  }

  cancelNavigation() {
    this.saveConfirmationForm.controls.confirmation.setValue(false);
    this.showUnsavedChangesModal = false;
  }

  private createCollaboratorInformationGroup(): UntypedFormGroup {
    return this.fb.group({
      subawardId: [],
      institutionId: [
        '',
        [
          Validators.max(2_147_483_647),
          Validators.min(0),
          Validators.pattern('\\d*'),
        ],
      ],
      institutionName: [],
      ein: [],
      registeredInSamGov: [false],
      institutionStatus: [],
      address: this.createAddressGroup(),
      parentDuns: [],
      dunsNumber: [],
      uniqueEntityId: [],
      supplierId: [],
      foreignInstitution: [false],
      congressionalDistrict: [],
      parentCongressionalDistrict: [],
      riskCategory: [],
      top5Q1: [false],
      top5Q2: [false],
      animalSubjects: [],
      humanSubjects: [],
      scopeOfWorkChanged: [],
      fundNumberChanged: [],
      clinicalResearch: [],
      submittedDate: [],
      descriptionOfAction: [],
      budget: this.createcollaboratorBudgetAndPerformanceGroup(),
      placeOfPerformance:
        this.createcollaboratorPrimaryPlaceOfPerformanceGroup(),
      institutionInformationFileName: [],
      institutionInformationFileId: [],
      institutionInformationDateUploaded: [],
      collaboratorScopeOfWorkFileName: [],
      collaboratorScopeOfWorkFileId: [],
      collaboratorScopeOfWorkDateUploaded: [],
      subawardAdvancePayment: [],
      collaboratorLetterOfIntentFileName: [],
      collaboratorLetterOfIntentFileId: [],
      dataMgmtSharingPlan: [],
      dataMgmtSharingPlanFileName: [],
      dataMgmtSharingPlanFileId: [],
      dataMgmtSharingPlanUploadedDate: [],
      dataMgmtSharingPlanUploadedBy: [''],
    });
  }

  private createcollaboratorBudgetAndPerformanceGroup(): UntypedFormGroup {
    return this.fb.group({
      sameAsGrantPeriod: [false],
      budgetFromDate: ['', DateValidator.usDate],
      budgetThruDate: ['', DateValidator.usDate],
      estimatedProjectPeriodFromDate: ['', DateValidator.usDate],
      estimatedProjectPeriodToDate: ['', DateValidator.usDate],
      fAndARate: [
        '',
        Validators.pattern(
          '(0*100{1,1}\\.?((?<=\\.)0*)?%?$)|(^0*\\d{0,3}\\.?((?<=\\.)\\d{0,2})?)$',
        ),
      ],
      costSharingAmount: [
        '0',
        [Validators.pattern('(\\d*)(\\.)?(\\d)*'), Validators.max(999999.99)],
      ],
      indirectCostBaseMethod: [],
      indirectCostBaseMethodDesc: [],
      directCost: ['', Validators.max(99999999.99)],
      indirectCost: ['', Validators.max(99999999.99)],
      totalCosts: ['', Validators.max(199999999.98)],
      estimatedTotalFunding: [],
      fAndARateAgreementFileName: [],
      fAndARateAgreementFileId: [],
    });
  }

  private createAddressGroup(): UntypedFormGroup {
    return this.fb.group({
      addressLine1: ['', Validators.maxLength(80)],
      addressLine2: ['', Validators.maxLength(80)],
      addressLine3: ['', Validators.maxLength(80)],
      addressLine4: ['', Validators.maxLength(80)],
      city: ['', Validators.maxLength(50)],
      state: ['', this.stateValidators],
      zip: ['', this.zipValidators],
      countryCode: ['', Validators.maxLength(3)],
      countryName: ['', Validators.maxLength(128)],
      congressionalDistrict: [
        '',
        [Validators.pattern('^[a-zA-Z0-9_@./#&+-]*$'), Validators.maxLength(6)],
      ],
    });
  }

  private createContactAddressGroup(): UntypedFormGroup {
    return this.fb.group({
      addressLine1: ['', Validators.maxLength(80)],
      addressLine2: ['', Validators.maxLength(80)],
      addressLine3: ['', Validators.maxLength(80)],
      addressLine4: ['', Validators.maxLength(80)],
      city: ['', Validators.maxLength(55)],
      state: ['', this.stateValidators],
      zip: ['', this.zipValidators],
      countryCode: ['', Validators.maxLength(3)],
      countryName: ['', Validators.maxLength(128)],
    });
  }

  private createcollaboratorPrimaryPlaceOfPerformanceGroup(): UntypedFormGroup {
    return this.fb.group({
      id: [],
      subrecipientId: [],
      placeDiffersFromContactsPage: [false],
      institutionName: ['', Validators.maxLength(254)],
      phone: ['', Validators.maxLength(30)],
      fax: ['', Validators.maxLength(30)],
      emailAddress: ['', Validators.maxLength(70)],
      address: this.createAddressGroup(),
    });
  }

  private setProjectInformation(result: Project) {
    const washUInfo = {
      projectId: result.id,
      proposalId: result.proposalId,
      subawardId: result.projectGroupId,
      awardDepartment: result.awardDepartment,
      awardYear: result.awardYear,
      awardNumber: result.awardNumber,
      sponsorName: result.sponsorName,
      wUPrincipalInvestigator:
        result.investigatorFirstName + ' ' + result.investigatorLastName,
      awardDepartmentName: result.investigatorDeptName,
      amendment: '0',
      status: 'IP',
      subClass: '38',
      subcontractType: '',
    };

    this.washUInformation.patchValue(washUInfo);

    const ProjectContact: SubawardContact = {
      washUDepartmentAdmin: {
        firstName: result.adminFirstName,
        lastName: result.adminLastName,
        emailAddress: result.adminEmail,
        phone: result.adminPhone,
        fax: result.adminFax,
      },
      washUPrincipalInvestigator: {
        firstName: result.investigatorFirstName,
        lastName: result.investigatorLastName,
        emailAddress: result.investigatorEmail,
        campusCD: result.investigatorCampusCode,
        principalInvestigatorDepartment: result.investigatorDept,
        deptName: result.investigatorDeptName,
        campusBox: result.investigatorCampusBox,
      },
    };

    this.contactForm.patchValue(ProjectContact);
    this.checklist.controls.noticeOfAward.setValue(
      result.noticeOfAwards.length > 0,
    );
    this.projectTitle = result.projectTitle;
    this.project = result;
  }

  private createContactGroupFromInstitution(instContact: InstitutionContact) {
    const instAddress = {
      addressLine1: instContact.address.addressLine1,
      addressLine2: instContact.address.addressLine2,
      addressLine3: instContact.address.addressLine3,
      addressLine4: instContact.address.addressLine4,
      city: instContact.address.city,
      state: instContact.address.state,
      zip: instContact.address.zip,
      countryCode: instContact.address.countryCode,
      countryName: instContact.address.countryName,
    };

    const contact = {
      firstName: instContact.firstName,
      middleName: instContact.middleName,
      lastName: instContact.lastName,
      nameSuffix: instContact.nameSuffix,
      phone: instContact.phone,
      fax: instContact.fax,
      emailAddress: instContact.email,
      address: instAddress,
    };

    return contact;
  }

  private createFinancialContactGroupFromInstitution(
    financialContact: InstitutionFinancialContact,
  ) {
    const contact = this.createContactGroupFromInstitution(financialContact);
    const invoiceEmail = { invoiceEmail: financialContact.invoiceEmail };
    const finContact = { ...contact, ...invoiceEmail };
    return finContact;
  }

  public async requestCollaboratorInformation() {
    this.sendRequestCollaboratorInfoEmail();
  }

  async confirmSubawardSave() {
    await this.save();
    this.canSubmitToOsrsModal = false;
    this.completeSubmitToOsrs();
  }

  discardSubawardChanges() {
    this.subawardForm.setValue(this.previousFormGroupValue);
    this.canSubmitToOsrsModal = false;
  }

  cancelSubawardNavigation() {
    this.saveConfirmationForm.controls.confirmation.setValue(false);
    this.canSubmitToOsrsModal = false;
  }

  public async submitToOsrs() {
    if (
      !this.subawardForm.controls.subrecipientInformation.get(
        'descriptionOfAction',
      ).value
    ) {
      this.showDescriptionofActionModal = true;
      return;
    }

    if (this.questionIsVisible && this.existsNullMonitoringAnswers()) {
      this.showMonitoringQuestionsModal = true;
      return;
    }

    const amendment =
      this.subawardForm.controls.washUInformation.get('amendment').value;

    if (
      this.subawardForm.controls.subrecipientInformation.get(
        'fundNumberChanged',
      ).value === null &&
      +amendment > 0
    ) {
      this.showFundChangedNotAnsweredModal = true;
      return;
    }

    if (
      this.subawardForm.controls.subrecipientInformation.get(
        'scopeOfWorkChanged',
      ).value === null &&
      +amendment > 0
    ) {
      this.showScopeOfWorkNotAnsweredModal = true;
      return;
    }

    if (!isEqual(this.subawardForm.value, this.previousFormGroupValue)) {
      this.canSubmitToOsrsModal = true;
    } else {
      await this.completeSubmitToOsrs();
    }
  }

  private existsNullMonitoringAnswers() {
    const fields = [];
    for (const field in this.subrecipientMonitoringQuestions.controls) {
      fields.push(this.subrecipientMonitoringQuestions.get(field).value.answer);
    }
    return fields.some((el) => el === null);
  }

  public async completeSubmitToOsrs() {
    const twoPlusAwardYear =
      this.subawardForm.controls.washUInformation.get('awardYear').value >= 2;

    if (twoPlusAwardYear) {
      this.showSubmitToOsrsModal = true;
    } else {
      await this.runSubmitToOsrsActions();
      await this.sendSubmitToOsrsEmail();
    }
  }

  public async confirmSubmitToOsrs() {
    await this.runSubmitToOsrsActions();
    this.showSubmitToOsrsModal = false;
    await this.sendSubmitToOsrsEmail();
  }

  private async runSubmitToOsrsActions() {
    this.setStatusToSubmittedToOsrs();
    this.subawardForm.controls.subrecipientInformation
      .get('submittedDate')
      .patchValue(new Date());
    await this.save();
  }

  public cancelSubmitToOsrs() {
    this.showSubmitToOsrsModal = false;
  }

  setStatusToSubmittedToOsrs() {
    this.subawardForm.controls.washUInformation.get('status').patchValue('SO');
    // Save occurs after the call to this function in completeSubmitToOsrs() and confirmSubmitToOsrs()
    // So no need to auto-save here
  }

  async sendSubmitToOsrsEmail() {
    this.showEmailConfirmation = true;

    const fromEmail = this.authService.currentUser.email;
    const toEmail = 'wucontracts@email.wustl.edu';
    const aggreementNumber = this.subawardForm.get(
      'washUInformation.agreementNumber',
    ).value;
    const washUPrincipalInvestigatorFirstName = this.contactForm.get(
      'washUPrincipalInvestigator.firstName',
    ).value;
    const washUPrincipalInvestigatorLastName = this.contactForm.get(
      'washUPrincipalInvestigator.lastName',
    ).value;
    const proposalId =
      this.subawardForm.controls.washUInformation.get('proposalId').value;
    const issuingFund =
      this.subawardForm.controls.washUInformation.get('fund').value;
    const institutionId =
      this.subawardForm.controls.subrecipientInformation.get(
        'institutionId',
      ).value;
    const institutionName =
      this.subawardForm.controls.subrecipientInformation.get(
        'institutionName',
      ).value;
    const subPrincipalInvestigatorFirstName = this.contactForm.get(
      'principalInvestigator.firstName',
    ).value;
    const subPrincipalInvestigatorLastName = this.contactForm.get(
      'principalInvestigator.lastName',
    ).value;
    const awardYear =
      this.subawardForm.controls.washUInformation.get('awardYear').value;
    const issuingDepartment =
      this.subawardForm.controls.washUInformation.get(
        'issuingDepartment',
      ).value;
    const budget = this.subawardForm.controls.washUInformation.get(
      'issuingFundSubClassBudgetObject',
    ).value;
    const amendmentNumber =
      this.subawardForm.controls.washUInformation.get('amendment').value;
    const subawardId =
      this.subawardForm.controls.washUInformation.get('subawardId').value;
    const awardNumber =
      this.subawardForm.controls.washUInformation.get('awardNumber').value;
    const sponsorName =
      this.subawardForm.controls.washUInformation.get('sponsorName').value;
    const indirectCost = this.subawardForm.controls.subrecipientInformation.get(
      'budget.indirectCost',
    ).value;
    const directCost =
      this.subawardForm.controls.subrecipientInformation.get(
        'budget.directCost',
      ).value;
    const fundingAmount =
      this.subawardForm.controls.subrecipientInformation.get(
        'budget.totalCosts',
      ).value;
    const budgetFromDate =
      this.subawardForm.controls.subrecipientInformation.get(
        'budget.budgetFromDate',
      ).value;
    const budgetThruDate =
      this.subawardForm.controls.subrecipientInformation.get(
        'budget.budgetThruDate',
      ).value;
    const poNumber = '';
    const trackingNumber =
      this.subawardForm.controls.washUInformation.get('trackingNumber').value;
    const cumulative = '';
    const ffataReportable = '';
    const wuDeptAdminFirstName = this.contactForm.get(
      'washUDepartmentAdmin.firstName',
    ).value;
    const wuDeptAdminLastName = this.contactForm.get(
      'washUDepartmentAdmin.lastName',
    ).value;
    const wuDeptAdminPhone = this.contactForm.get(
      'washUDepartmentAdmin.phone',
    ).value;
    const wuDeptAdminEmail = this.contactForm.get(
      'washUDepartmentAdmin.emailAddress',
    ).value;
    const estimatedTotalFunding =
      this.subawardForm.controls.subrecipientInformation.get(
        'budget.estimatedTotalFunding',
      ).value;

    this.emailsClient
      .postSubmitToOsrsEmail(
        fromEmail,
        toEmail,
        aggreementNumber,
        washUPrincipalInvestigatorFirstName,
        washUPrincipalInvestigatorLastName,
        proposalId,
        issuingFund,
        institutionId,
        institutionName,
        subPrincipalInvestigatorFirstName,
        subPrincipalInvestigatorLastName,
        awardYear,
        issuingDepartment,
        budget,
        amendmentNumber,
        subawardId,
        awardNumber,
        sponsorName,
        indirectCost,
        directCost,
        fundingAmount,
        budgetFromDate,
        budgetThruDate,
        poNumber,
        trackingNumber,
        cumulative,
        ffataReportable,
        wuDeptAdminFirstName,
        wuDeptAdminLastName,
        wuDeptAdminPhone,
        wuDeptAdminEmail,
        estimatedTotalFunding,
      )
      .subscribe(
        () => {
          this.emailResult.next('Sent Subaward Submission to OSRS.');
        },
        (error) => {
          this.emailResult.next('Failed to send Subaward Submission to OSRS.');
        },
      );
  }

  public returnToProjectSummary() {
    this.router.navigateByUrl(
      '/project/' +
        this.subawardForm.controls.washUInformation.get('projectId').value,
    );
  }

  changesToCurrentYear() {
    const restrictedCodes = ['IP', 'AC', 'CIC', 'PSO', 'RC', 'RD', 'SRV'];
    const currentStatusCode =
      this.subawardForm.controls.washUInformation.get('status').value;

    this.restrictedStatuses = this.activeStatusList
      .filter((status) => restrictedCodes.includes(status.statusCode))
      .map((status) => status.statusDescription);

    if (
      restrictedCodes.includes(currentStatusCode) &&
      !this.userRole.includes('OSRS')
    ) {
      this.showChangesToCurrentYearModal = true;
    } else {
      this.isModifiedAgreement = true;
      this.router.navigateByUrl(
        '/subaward/changesToCurrentYear/' + this.subawardForm.controls.id.value,
      );
    }
  }

  ngOnInit() {
    this.setActiveTab('details');
    this.userRole = this.authService.currentUser.role;
    this.userEmail = this.authService.currentUser.email;

    this.subStatusService.ActiveStatuses.subscribe(async (value) => {
      if (value !== undefined && value !== null) {
        this.activeStatusList = value;
      }
    });
    this.subawardsService.Approved.subscribe(async (value) => {
      if (value !== undefined && value !== null) {
        this.subawardForm.controls.approved.patchValue(value);
      }
    });
    this.subawardsService.InternalDistributionSent.subscribe(async (value) => {
      if (value !== undefined && value !== null) {
        this.subawardForm.controls.internalDistributionSent.patchValue(value);
      }
    });
    this.subawardsService.UnilateralModSent.subscribe(async (value) => {
      if (value !== undefined && value !== null) {
        this.subawardForm.controls.unilateralModSent.patchValue(value);
      }
    });
    this.subawardsService.PoNotificationSent.subscribe(async (value) => {
      if (value !== undefined && value !== null) {
        this.subawardForm.controls.poNotificationSent.patchValue(value);
      }
    });
    this.mainLayoutService.toggleProjectSidebarVisibility(true);
    this.featureService.featureEnabled('WDCostCenter').subscribe((feature) => {
      if (feature && feature.featureName) {
        if (feature.featureName.toLowerCase() === 'wdcostcenter') {
          this.isActiveFeatureCostCenter = feature.featureEnabled;
        }
      }
      this.subawardsService.PoCoEncumbId.subscribe(async (value) => {
        if (value) {
          if (
            this.previousPoCoEncumbId !== undefined &&
            value !== this.previousPoCoEncumbId &&
            !this.isActiveFeatureCostCenter // feature turned OFF
          ) {
            this.previousPoCoEncumbId = value;
          }
        }
      });
      this.subawardsService.SupplierContractId.subscribe(async (value) => {
        if (value) {
          if (
            this.previousSupplierContractId !== undefined &&
            value !== this.previousSupplierContractId &&
            this.isActiveFeatureCostCenter // feature turned ON
          ) {
            this.previousSupplierContractId = value;
          }
        }
      });
    });
    this.subawardForm.controls.contact.valueChanges.subscribe((contacts) => {
      if (contacts && contacts.washUPrincipalInvestigator) {
        this.subawardForm.controls.washUInformation
          .get('principalInvestigatorName')
          .patchValue(
            contacts.washUPrincipalInvestigator.firstName +
              ' ' +
              contacts.washUPrincipalInvestigator.lastName,
          );
      }
    });
    this.subawardForm.controls.washUInformation
      .get('subcontractType')
      .valueChanges.subscribe((value) => {
        if (value !== undefined && value !== null) {
          this.fixedRate = value === 'Fixed Price';
        }
      });
    this.subawardForm.controls.washUInformation
      .get('assignedGA')
      .valueChanges.subscribe((value) => {
        if (value !== undefined && value !== null) {
          const previousAssignedGA =
            this.subawardForm.controls.washUInformation.get(
              'previousAssignedGA',
            ).value;
          if (
            previousAssignedGA === undefined ||
            previousAssignedGA === null ||
            previousAssignedGA === ''
          ) {
            this.subawardForm.controls.washUInformation
              .get('previousAssignedGA')
              .patchValue(value);
          }
        }
      });
    this.subawardForm.controls.washUInformation
      .get('previousAssignedGAFirstName')
      .valueChanges.subscribe((value) => {
        if (value !== undefined && value !== null) {
          const firstName = value;
          const lastName = this.subawardForm.controls.washUInformation.get(
            'previousAssignedGALastName',
          ).value;
          const fullName = this.setPreviousOsrsGaFullName(firstName, lastName);
          this.subawardForm.controls.washUInformation
            .get('previousAssignedGA')
            .patchValue(fullName);
        }
      });
    this.subawardForm.controls.washUInformation
      .get('previousAssignedGALastName')
      .valueChanges.subscribe((value) => {
        if (value !== undefined && value !== null) {
          const firstName = this.subawardForm.controls.washUInformation.get(
            'previousAssignedGAFirstName',
          ).value;
          const lastName = value;
          const fullName = this.setPreviousOsrsGaFullName(firstName, lastName);
          this.subawardForm.controls.washUInformation
            .get('previousAssignedGA')
            .patchValue(fullName);
        }
      });
    this.subawardForm.controls.washUInformation
      .get('supplierContractId')
      .valueChanges.subscribe((supplierContractId) => {
        if (supplierContractId !== undefined && supplierContractId !== null) {
          this.subawardForm.controls.washUInformation
            .get('supplierContractId')
            .patchValue(
              this.subawardForm.controls.washUInformation
                .get('supplierContractId')
                .value.toUpperCase(),
              { emitEvent: false },
            );
        }
      });
    this.subawardForm.controls.washUInformation
      .get('amendment')
      .valueChanges.subscribe((amendment) => {
        if (amendment !== undefined && amendment !== null && +amendment > 0) {
          this.isModifiedAgreement = true;
        }
        if (amendment !== undefined && amendment !== null) {
          this.setMonitoringQuestionsVisibility();
        }
      });
    this.subawardForm.controls.washUInformation
      .get('awardYear')
      .valueChanges.subscribe((awardYear) => {
        if (awardYear !== undefined && awardYear !== null) {
          this.setMonitoringQuestionsVisibility();
        }
      });
    this.subawardForm.controls.washUInformation
      .get('status')
      .valueChanges.subscribe((status) => {
        if (status) {
          this.subawardsService.setsubawardStatus(status);
          this.inactiveStatus = this.inactiveStatusList.includes(status);
          // successful emails check
          if (this.previousStatus) {
            if (
              this.previousStatus === 'SD' &&
              this.previousStatus !== status
            ) {
              // Set Approve to 'not clicked' && emails triggered by Approve to false
              this.subawardsService.setApproved(false);
              this.subawardsService.setInternalDistributionSent(false);
              this.subawardsService.setUnilateralModSent(false);
            }
          }
        }
      });
    this.subawardForm.controls.subrecipientInformation
      .get('dataMgmtSharingPlanFileId')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.dmspFile = true;
          this.showDMSPModal = false;
        } else {
          this.dmspFile = false;
        }
      });
    let dmspModalCount = 0;
    this.subawardForm.controls.subrecipientInformation
      .get('dataMgmtSharingPlan')
      .valueChanges.subscribe((value) => {
        if (value) {
          if (
            !this.dmspFile &&
            this.subawardForm.controls.subrecipientInformation.get(
              'dataMgmtSharingPlan',
            ).touched &&
            dmspModalCount === 0
          ) {
            this.showDMSPModal = true;
            this.subawardForm.controls.subrecipientInformation
              .get('dataMgmtSharingPlan')
              .markAsUntouched();
            dmspModalCount++;
          }
        } else {
          this.showDMSPModal = false;
        }
      });
    this.subawardForm.controls.subrecipientInformation
      .get('budget.indirectCostBaseMethod')
      .valueChanges.subscribe((value) => {
        this.subawardsService.setIndirectCostBaseMethod(value);
        if (value && value.toLowerCase() === 'othr') {
          this.setRequiredControlsBudgetForm(this.requiredControlsBudgetForm);
        } else {
          this.resetRequiredControlsBudgetForm(this.requiredControlsBudgetForm);
        }
      });
    this.subawardForm.controls.subrecipientMonitoringQuestions.valueChanges.subscribe(
      (value) => {
        const fields = this.subrecipientMonitoringQuestions.controls.map(
          (control) => control.get('answer')?.value,
        );
        const hasNoAnswer = fields.includes(false);
        if (hasNoAnswer && !this.monitoringQuestionsReminderModalShown) {
          this.showMonitoringQuestionsReminderModal = true;
          this.monitoringQuestionsReminderModalShown = true;
        }
      },
    );
    combineLatest(
      this.activatedRoute.params.pipe(distinctUntilChanged()),
      this.activatedRoute.queryParams.pipe(distinctUntilChanged()),
    ) // distinctUntilChanged to avoid triggering subscription twice if routeParams and queryParams changed at he same time
      .pipe(
        map(([params, queryParams]) => ({ params, queryParams })),
        mergeMap(({ params, queryParams }) => {
          this.subawardId = Number(params.id);
          const institutionId = queryParams.institutionid;
          const projectId = params.projectId;
          const cloneSubawardId = params.cloneId;
          const tab = params.tab;
          const observables: Observable<any>[] = [];

          if (tab) {
            this.activeTab[tab] = true;
          }
          if (cloneSubawardId) {
            this.previousFormGroupValue = cloneDeep(this.subawardForm.value);

            observables.push(
              this.subawardsClient.clone(cloneSubawardId).pipe(
                tap(() => {
                  this.isCloneAction = true;
                  this.mainLayoutService.toggleSubawardSidebarVisibility(false);
                  this.mainLayoutService.toggleCurrentYearSidebarVisibility(
                    true,
                  );
                  this.mainLayoutService.setSubawardId(cloneSubawardId);
                }),
                catchError((error) => {
                  try {
                    const parsedError = JSON.parse(error.error);
                    if (Array.isArray(parsedError)) {
                      const validationMessage = parsedError
                        .map((e) => e.Error)
                        .join('<br>');
                      this.feedbackService.alert(validationMessage);
                    } else {
                      this.feedbackService.alert(
                        'An error occurred during the "Changes to current Year" action.',
                      );
                    }
                  } catch (parsedError) {
                    this.feedbackService.alert(
                      'An error occurred during the "Changes to current Year" action.',
                    );
                  }

                  // Navigate back to original subaward
                  setTimeout(() => {
                    this.router.navigate([`/subaward/${cloneSubawardId}`], {
                      replaceUrl: true,
                    });
                  }, 5000);

                  return of(null);
                }),
              ),
            );
          } else {
            if (this.subawardId) {
              this.subawardsService.selectSubaward(this.subawardId);
              observables.push(this.subawardsClient.get(this.subawardId));
              this.mainLayoutService.toggleSubawardSidebarVisibility(true);
              this.mainLayoutService.toggleAddSubawardSidebarVisibility(false);
              this.mainLayoutService.setSubawardId(this.subawardId);
            } else {
              observables.push(of(null));
              this.subawardsService.setPoCoRequestId(
                !!this.subawardForm.controls.washUInformation.get('poCoDocId')
                  .value,
              );
              this.mainLayoutService.toggleSubawardSidebarVisibility(false);
              this.mainLayoutService.toggleAddSubawardSidebarVisibility(true);
            }
          }
          if (institutionId) {
            observables.push(this.institutionsClient.get(institutionId));
          } else {
            observables.push(of(null));
          }
          if (projectId) {
            observables.push(this.projectsClient.get(projectId));
          } else {
            observables.push(of(null));
          }
          return forkJoin(observables);
        }),
        mergeMap(([subaward, updatedInstitution, projectInfo]) => {
          if (subaward) {
            this.setSubawardForm(subaward);

            // set initial 'email sent' values in service
            this.subawardsService.setInternalDistributionSent(
              this.subawardForm.controls.internalDistributionSent.value,
            );
            this.subawardsService.setPoNotificationSent(
              this.subawardForm.controls.poNotificationSent.value,
            );
            this.subawardsService.setUnilateralModSent(
              this.subawardForm.controls.unilateralModSent.value,
            );
            this.subawardsService.setApproved(
              this.subawardForm.controls.approved.value,
            );
            const hasFeaFile =
              this.subawardForm.controls.fullyExecutedAgreement.get('fileId')
                .value !== ''
                ? true
                : false;
            this.subawardsService.setFeaUploaded(hasFeaFile);
            this.feaFileUploaded = hasFeaFile;

            this.previousPoCoEncumbId =
              this.subawardForm.controls.washUInformation.get(
                'poCoEncumbId',
              ).value;

            this.previousSupplierContractId =
              this.subawardForm.controls.washUInformation.get(
                'supplierContractId',
              ).value;

            this.previousStatus =
              this.subawardForm.controls.washUInformation.get('status').value;

            this.formLevelAlerts$.next(null);

            return this.projectsClient
              .get(
                this.subawardForm.controls.washUInformation.get('projectId')
                  .value,
              )
              .pipe(
                tap((project) => {
                  this.checklist.controls.noticeOfAward.setValue(
                    project.noticeOfAwards.length > 0,
                  );
                  this.projectTitle = project.projectTitle;
                  this.project = project;
                }),
                map((project) => [subaward, updatedInstitution, projectInfo]),
              );
          }

          return of([subaward, updatedInstitution, projectInfo]);
        }),
      )
      .subscribe(([subaward, updatedInstitution, projectInfo]) => {
        this.updatedInstitution = updatedInstitution;

        if (this.updatedInstitution) {
          this.institutionSearchService.select(this.updatedInstitution);
        }

        if (projectInfo) {
          this.setProjectInformation(projectInfo);
          this.formLevelAlerts$.next(null);
          this.previousFormGroupValue = cloneDeep(this.subawardForm.value);
        }

        this.disableButtons.next(false);
      });

    this.institutionSearchService.getSelectedInstitution().subscribe((inst) => {
      let instAddress = {};
      if (inst !== null && inst !== undefined) {
        if (
          inst.institutionAddress !== null &&
          inst.institutionAddress !== undefined
        ) {
          instAddress = {
            addressLine1: inst.institutionAddress.addressLine1,
            addressLine2: inst.institutionAddress.addressLine2,
            addressLine3: inst.institutionAddress.addressLine3,
            addressLine4: inst.institutionAddress.addressLine4,
            city: inst.institutionAddress.city,
            state: inst.institutionAddress.state,
            zip: inst.institutionAddress.zip,
            countryCode: inst.institutionAddress.countryCode,
            countryName: inst.institutionAddress.countryName,
            congressionalDistrict: inst.congressionalDistrict,
          };
        }
        const subInstInfo = {
          institutionId: inst.id,
          institutionName: inst.institutionName,
          ein: inst.employerIdNumber,
          registeredInSamGov: inst.samGov,
          institutionStatus: inst.status,
          parentDuns: inst.parentDuns,
          dunsNumber: inst.dunsNumber,
          uniqueEntityId: inst.uniqueEntityId,
          supplierId: inst.supplierId,
          foreignInstitution: inst.foreign,
          congressionalDistrict: inst.congressionalDistrict,
          parentCongressionalDistrict: inst.parentCongressionalDistrict,
          riskCategory: inst.riskCategory,
          top5Q1: inst.top5Q1,
          top5Q2: inst.top5Q2,
          address: instAddress,
        };
        this.subawardForm.controls.subrecipientInformation.patchValue(
          subInstInfo,
        );

        this.subawardForm.controls.subrecipientInformation
          .get('placeOfPerformance.institutionName')
          .patchValue(subInstInfo.institutionName);

        this.subawardForm.controls.subrecipientInformation
          .get('placeOfPerformance.address')
          .patchValue(instAddress);

        this.contactForm.controls.signatory.patchValue(
          this.createContactGroupFromInstitution(inst.signatory),
        );
        this.contactForm.controls.institutionalAdmin.patchValue(
          this.createContactGroupFromInstitution(inst.adminPointOfContact),
        );
        this.contactForm.controls.institutionalFinancial.patchValue(
          this.createFinancialContactGroupFromInstitution(inst.financeContact),
        );
        this.contactForm.controls.centralOfficeEmail.setValue(
          inst.centralOfficeEmail,
        );
      }
      this.returnCodesClient.get().subscribe((val) => {
        this.returnCodes = val;
      });
    });

    this.checklistService.checklistCompleted.subscribe((value) => {
      this.checkListCompleted = value;
      this.canSubmitToOsrs(); // re-deploying
      // moving PSO status change logic to 'save()'
    });

    // =================Set Validators Based on the Country Selection=================================================
    this.SetValidatorsBasedOnCountry(
      this.subawardForm.controls.contact.get('signatory.address'),
    );
    this.SetValidatorsBasedOnCountry(
      this.subawardForm.controls.contact.get('institutionalAdmin.address'),
    );
    this.SetValidatorsBasedOnCountry(
      this.subawardForm.controls.contact.get('institutionalFinancial.address'),
    );
    this.SetValidatorsBasedOnCountry(
      this.subawardForm.controls.contact.get('principalInvestigator.address'),
    );
    this.SetValidatorsBasedOnCountry(
      this.subawardForm.controls.subrecipientInformation.get(
        'placeOfPerformance.address',
      ),
    );
    // ================================================================================

    // store active user infor in a userProfileList object for later reference
    interface UserNameEmail {
      userName?: string | undefined;
      userEmail?: string | undefined;
      userWUSTLId?: number | undefined;
      userRole?: string | undefined;
    }
    this.userProfileList = new Map<string, UserNameEmail>();
    this.userProfileClient.get().subscribe((val) => {
      val.forEach((u) => {
        if (u.isActive && u.employeeName != null) {
          this.userProfileList.set(u.employeeName, {
            userName: u.employeeName,
            userEmail: u.email,
            userWUSTLId: u.wustlId,
            userRole: u.role,
          });
        }
      });
    });
  }

  private setPreviousOsrsGaFullName(firstName: string, lastName: string) {
    const nameFirst = firstName !== null ? firstName : '';
    const nameLast = lastName !== null ? lastName : '';
    if (nameFirst.length > 0 || nameLast.length > 0) {
      return `${nameLast + ', ' + nameFirst}`;
    }
    return '';
  }

  private setRequiredControlsBudgetForm(requiredControls: RequiredControls[]) {
    requiredControls.forEach((control) => {
      control.validators = control.validators.concat(Validators.required);

      const indirectCostBaseMethodDescControlName =
        'budget.' + control.controlName;

      this.subawardForm.controls.subrecipientInformation
        .get(indirectCostBaseMethodDescControlName)
        .setValidators(control.validators);
    });
    this.subawardForm.updateValueAndValidity();
  }

  private resetRequiredControlsBudgetForm(
    requiredControls: RequiredControls[],
  ) {
    requiredControls.forEach((control) => {
      control.validators = control.validators.filter(
        (val) => val.name !== 'required',
      );

      const indirectCostBaseMethodDescControlName =
        'budget.' + control.controlName;

      this.subawardForm.controls.subrecipientInformation
        .get(indirectCostBaseMethodDescControlName)
        .setValidators(control.validators);

      this.subawardForm.controls.subrecipientInformation
        .get(indirectCostBaseMethodDescControlName)
        .setErrors(null);
      this.feedbackService.alert(null);
      this.subawardForm.updateValueAndValidity();
    });
  }

  private SetValidatorsBasedOnCountry(addressComponent): void {
    const countryList = addressComponent.get('countryCode');
    const stateList = addressComponent.get('state');
    const zipList = addressComponent.get('zip');

    // Set Validators Based on the Country Selection
    countryList.valueChanges.subscribe((country) => {
      if (country === 'USA' || country === 'CAN') {
        // add Validators.required
        stateList.setValidators(
          this.stateValidators.concat(Validators.required),
        );
        zipList.setValidators(this.zipValidators.concat(Validators.required));
        stateList.updateValueAndValidity();
        zipList.updateValueAndValidity();
      } else {
        // set to "normal" validators (not required)
        stateList.setValidators(this.stateValidators);
        zipList.setValidators(this.zipValidators);
        stateList.updateValueAndValidity();
        zipList.updateValueAndValidity();
      }
    });
  }

  private canSubmitToOsrs() {
    if (this.checkListCompleted && !this.viewOnlyUser()) {
      this.disableSubmitToOsrs.next(false);
    } else {
      this.disableSubmitToOsrs.next(true);
    }
  }

  private setSubawardForm(result: Subaward) {
    this.subawardForm.controls.subrecipientInformation
      .get('subawardId')
      .setValue(result.id);

    const subawardResult = result;

    if (!subawardResult.subrecipientInformation.budget.costSharingAmount) {
      subawardResult.subrecipientInformation.budget.costSharingAmount =
        this.subawardForm.get(
          'subrecipientInformation.budget.costSharingAmount',
        ).value;
    }

    subawardResult.washUInformation.signatureDate =
      DateConvert.convertDateToLocalCulture(
        result.washUInformation.signatureDate,
      );
    subawardResult.washUInformation.subReceived =
      DateConvert.convertDateToLocalCulture(
        result.washUInformation.subReceived,
      );
    subawardResult.washUInformation.approvalDate =
      DateConvert.convertDateToLocalCulture(
        result.washUInformation.approvalDate,
      );
    subawardResult.washUInformation.disclosureCompletionDate =
      DateConvert.convertDateToLocalCulture(
        result.washUInformation.disclosureCompletionDate,
      );
    subawardResult.washUInformation.educationModuleCompletionDate =
      DateConvert.convertDateToLocalCulture(
        result.washUInformation.educationModuleCompletionDate,
      );
    subawardResult.washUInformation.coiEmailSent =
      DateConvert.convertDateToLocalCulture(
        result.washUInformation.coiEmailSent,
      );

    subawardResult.subrecipientInformation.budget.budgetFromDate =
      DateConvert.convertDateToLocalCulture(
        result.subrecipientInformation.budget.budgetFromDate,
      );
    subawardResult.subrecipientInformation.budget.budgetThruDate =
      DateConvert.convertDateToLocalCulture(
        result.subrecipientInformation.budget.budgetThruDate,
      );
    subawardResult.subrecipientInformation.budget.estimatedProjectPeriodFromDate =
      DateConvert.convertDateToLocalCulture(
        result.subrecipientInformation.budget.estimatedProjectPeriodFromDate,
      );
    subawardResult.subrecipientInformation.budget.estimatedProjectPeriodToDate =
      DateConvert.convertDateToLocalCulture(
        result.subrecipientInformation.budget.estimatedProjectPeriodToDate,
      );
    subawardResult.subrecipientInformation.institutionInformationDateUploaded =
      DateConvert.convertDateToLocalCulture(
        result.subrecipientInformation.institutionInformationDateUploaded,
      );
    subawardResult.subrecipientInformation.dataMgmtSharingPlanUploadedDate =
      DateConvert.convertDateToLocalCulture(
        result.subrecipientInformation.dataMgmtSharingPlanUploadedDate,
      );
    subawardResult.subrecipientInformation.collaboratorScopeOfWorkDateUploaded =
      DateConvert.convertDateTimeToLocalCulture(
        result.subrecipientInformation.collaboratorScopeOfWorkDateUploaded,
      );

    if (
      this.subawardForm.get(
        'subrecipientInformation.budget.fAndARateAgreementFileId',
      ).value
    ) {
      subawardResult.subrecipientInformation.budget.fAndARateAgreementFileName =
        this.subawardForm.get(
          'subrecipientInformation.budget.fAndARateAgreementFileName',
        ).value;
    }

    if (
      this.subawardForm.get(
        'subrecipientInformation.collaboratorScopeOfWorkFileId',
      ).value
    ) {
      subawardResult.subrecipientInformation.collaboratorScopeOfWorkFileName =
        this.subawardForm.get(
          'subrecipientInformation.collaboratorScopeOfWorkFileName',
        ).value;
    }

    if (
      this.subawardForm.get(
        'subrecipientInformation.institutionInformationFileId',
      ).value
    ) {
      subawardResult.subrecipientInformation.institutionInformationFileName =
        this.subawardForm.get(
          'subrecipientInformation.institutionInformationFileName',
        ).value;
    }

    if (
      this.subawardForm.get(
        'subrecipientInformation.collaboratorLetterOfIntentFileId',
      ).value
    ) {
      subawardResult.subrecipientInformation.collaboratorLetterOfIntentFileName =
        this.subawardForm.get(
          'subrecipientInformation.collaboratorLetterOfIntentFileName',
        ).value;
    }

    this.additionalDocuments.clear();
    this.additionalDocumentControlFactory
      .createMany(subawardResult.additionalDocuments.length)
      .forEach((control) => this.additionalDocuments.push(control));

    this.contractAttachments.clear();
    this.contractAttachmentControlFactory
      .createMany(subawardResult.contractAttachments.length)
      .forEach((control) => this.contractAttachments.push(control));

    this.subrecipientMonitoringQuestions.clear();
    this.monitoringQuestionContolFactory
      .createMany(subawardResult.subrecipientMonitoringQuestions.length)
      .forEach((control) => this.subrecipientMonitoringQuestions.push(control));

    if (!subawardResult.fullyExecutedAgreement) {
      subawardResult.fullyExecutedAgreement = {
        fileId: '',
        fileName: '',
        dateUploaded: '',
      };
    } else {
      subawardResult.fullyExecutedAgreement.dateUploaded =
        this.datePipe.transform(
          result.fullyExecutedAgreement.dateUploaded,
          'medium',
        );
    }
    this.questionIsVisible = subawardResult.washUInformation.awardYear >= 2;
    const amendment = this.washUInformation.get('amendment').value;
    if (amendment) {
      this.isModifiedAgreement = +amendment > 0;
    }

    this.subawardForm.patchValue(subawardResult);

    this.previousFormGroupValue = cloneDeep(this.subawardForm.value);

    this.subawardsService.setPoCoRequestId(
      !!this.subawardForm.controls.washUInformation.get('poCoDocId').value,
    );

    this.previousFormGroupValue = cloneDeep(this.subawardForm.value);
  }

  onCollaboratorInstitutionSelect(show: boolean): void {
    this.showCollaboratorInstitutionAlert = show;
    this.cdr.detectChanges();
  }

  addAdditionalDocument(additionalDocument: UntypedFormGroup): void {
    this.additionalDocuments.push(additionalDocument);
  }

  removeAdditionalDocument(index: number): void {
    this.additionalDocuments.removeAt(index);
  }

  addContractAttachment(contractAttachment: UntypedFormGroup): void {
    this.contractAttachments.push(contractAttachment);
    this.save();
  }

  removeContractAttachment(index: number): void {
    this.contractAttachments.removeAt(index);
    this.save();
  }

  private createContactGroup(): UntypedFormGroup {
    return this.fb.group({
      firstName: ['', Validators.maxLength(30)],
      middleName: ['', Validators.maxLength(30)],
      lastName: ['', Validators.maxLength(30)],
      nameSuffix: ['', Validators.maxLength(15)],
      phone: ['', Validators.maxLength(30)],
      fax: ['', Validators.maxLength(30)],
      emailAddress: [],
      address: this.createContactAddressGroup(),
      campusCD: ['', Validators.maxLength(1)],
      campusBox: [''],
      principalInvestigatorDepartment: [''],
      primaryDivision: [],
    });
  }

  private createFinanceContactGroup(): UntypedFormGroup {
    const financeGroup = this.createContactGroup();
    financeGroup.addControl('invoiceEmail', new UntypedFormControl(''));
    return financeGroup;
  }

  async successfulSubResponse(result: ManageSubawardResponse) {
    this.setSubawardForm(result.subaward);
    this.previousFormGroupValue = cloneDeep(this.subawardForm.value);
    this.previousStatus =
      this.subawardForm.controls.washUInformation.get('status').value;
    this.saveConfirmationForm.controls.confirmation.setValue(true);
    this.subawardsService.setPoCoRequestId(
      !!this.subawardForm.controls.washUInformation.get('poCoDocId').value,
    );
    this.mainLayoutService.toggleSubawardSidebarVisibility(true);
    this.mainLayoutService.toggleAddSubawardSidebarVisibility(false);
    this.mainLayoutService.setSubawardId(result.subaward.id);
    if (this.redirectToProjectSummary) {
      await this.delay(this.msToDelay); // needed to give user time to view confirmation message(s)
      this.router.navigate([
        'project/' +
          this.subawardForm.controls.washUInformation.get('projectId').value,
      ]);
    }
    if (result.warnings !== undefined && result.warnings.length > 0) {
      result.warnings.forEach((warning) => {
        if (warning.warningMessageKey === 'SupplierContractId') {
          this.invalidSupplierContractId = true;
          this.supplierContractIdWarningMessage.push(warning);
        } else {
          this.invalidSupplierContractId = false;
        }
      });
      this.showSupplierContractIdModal = true;
    } else {
      this.invalidSupplierContractId = false;
    }

    // auto-close for 'SubAward Saved Modal'
    setTimeout(() => {
      this.showSubAwardSavedModal = false;
    }, 750);
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async save() {
    if (this.subawardForm.invalid) {
      markFormGroupTouched(this.subawardForm);
      if (
        this.subawardForm.controls.subrecipientInformation
          .get('institutionId')
          .hasError('required')
      ) {
        this.feedbackService.alert(
          'A Collaborating Institution must be selected or when adding a new institution, the Institution Name needs to be entered and saved with an Institution ID assigned.',
        );
        return;
      }

      // check state and zip code lists
      if (this.checkStatesAndZips()) {
        return; // has errors
      }

      this.validateAttachmentSequence();
      this.feedbackService.alert(
        'Please fix all field errors before saving the form.',
      );
      return;
    }
    if (
      this.isCloneAction &&
      !this.subawardForm.controls.subrecipientInformation.get(
        'descriptionOfAction',
      ).value
    ) {
      this.subawardForm.controls.subrecipientInformation
        .get('descriptionOfAction')
        .setValidators(Validators.required);
      markFormGroupTouched(this.subawardForm);

      this.feedbackService.alert(
        'Please enter a Description of the Action before saving the form.',
      );
      return;
    }

    if (this.isModifiedAgreement && this.checkListCompleted) {
      if (
        this.subawardForm.controls.subrecipientInformation.get(
          'fundNumberChanged',
        ).value === null
      ) {
        this.showFundChangedNotAnsweredModal = true;
      }
      if (
        this.subawardForm.controls.subrecipientInformation.get(
          'scopeOfWorkChanged',
        ).value === null
      ) {
        this.showScopeOfWorkNotAnsweredModal = true;
      }
    }

    const popAddress = this.subawardForm.controls.subrecipientInformation.get(
      'placeOfPerformance.address',
    );
    const popCountry = popAddress.get('countryCode').value; // popCountry is my favorite music genre :)
    const conDistr = popAddress.get('congressionalDistrict').value;

    if (
      conDistr !== null &&
      conDistr !== undefined &&
      conDistr !== '' &&
      popCountry !== 'USA'
    ) {
      this.showCongressionalDistrictModal = true;
    }

    const hasInstitutionOfficerError = await this.validateInstitutionOfficers(
      this.subawardForm.controls.institutionOfficers.value,
    );

    if (hasInstitutionOfficerError) {
      this.feedbackService.alert(
        'Error - Compensated Officer salary information is incorrect. Enter numeric salary or leave blank on the spreadsheet.',
      );
      return;
    }

    // PSO/IP Status change logic
    const currentStatus =
      this.subawardForm.controls.washUInformation.get('status').value;
    if (this.checkListCompleted) {
      this.canSubmitToOsrs();

      if (currentStatus) {
        const statusesToChange = ['IP', 'AC', 'CIC', 'PSO', 'SRV'];
        if (
          this.checkListCompleted &&
          statusesToChange.includes(currentStatus) &&
          currentStatus !== 'PSO'
        ) {
          this.subawardForm.controls.washUInformation
            .get('status')
            .patchValue('PSO');
          this.showPsoStatusChangeModal = true;
        }
      }
    } else {
      this.canSubmitToOsrs();
      if (currentStatus === 'PSO') {
        this.subawardForm.controls.washUInformation
          .get('status')
          .patchValue('IP');
        this.showIpStatusChangeModal = true;
      }
    }

    this.feedbackService.beginLoading();

    if (!this.subawardForm.controls.id.value) {
      this.subawardsClient
        .post(this.subawardForm.value)
        .pipe(this.feedbackService.provideFeedback())
        .subscribe((result) => {
          this.subawardForm.controls.subrecipientInformation
            .get('subawardId')
            .setValue(result.subaward.id);
          this.successfulSubResponse(result);
          const id = this.subawardForm.controls.id.value;
          this.location.replaceState(`/subaward/${id}`);
          this.isCloneAction = false;
          this.subawardForm.controls.subrecipientInformation
            .get('descriptionOfAction')
            .clearValidators();
          this.showSubAwardSavedModal = true;
        });
    } else {
      this.subawardsClient
        .put(this.subawardForm.controls.id.value, this.subawardForm.value)
        .pipe(this.feedbackService.provideFeedback())
        .subscribe((result) => {
          this.successfulSubResponse(result);
          this.showSubAwardSavedModal = true;
        });
    }
  }

  private validateInstitutionOfficers(officers: InstitutionOfficerVm[]) {
    if (officers && officers.length > 0) {
      return officers.some(
        (officer) =>
          officer.officerName === null ||
          officer.compensation === null ||
          !isNumeric(officer.compensation),
      );
    }
  }

  private checkStatesAndZips(): boolean {
    if (
      this.validateStates(
        this.subawardForm.controls.contact.get('signatory.address'),
      )
    ) {
      return true; // has errors
    }
    if (
      this.validateStates(
        this.subawardForm.controls.contact.get('institutionalAdmin.address'),
      )
    ) {
      return true; // has errors
    }
    if (
      this.validateStates(
        this.subawardForm.controls.contact.get(
          'institutionalFinancial.address',
        ),
      )
    ) {
      return true; // has errors
    }
    if (
      this.validateStates(
        this.subawardForm.controls.contact.get('principalInvestigator.address'),
      )
    ) {
      return true; // has errors
    }
    if (
      this.validateStates(
        this.subawardForm.controls.subrecipientInformation.get(
          'placeOfPerformance.address',
        ),
      )
    ) {
      return true; // has errors
    }
    if (
      this.validateZipCodes(
        this.subawardForm.controls.contact.get('signatory.address'),
      )
    ) {
      return true; // has errors
    }
    if (
      this.validateZipCodes(
        this.subawardForm.controls.contact.get('institutionalAdmin.address'),
      )
    ) {
      return true; // has errors
    }
    if (
      this.validateZipCodes(
        this.subawardForm.controls.contact.get(
          'institutionalFinancial.address',
        ),
      )
    ) {
      return true; // has errors
    }
    if (
      this.validateZipCodes(
        this.subawardForm.controls.contact.get('principalInvestigator.address'),
      )
    ) {
      return true; // has errors
    }
    if (
      this.validateZipCodes(
        this.subawardForm.controls.subrecipientInformation.get(
          'placeOfPerformance.address',
        ),
      )
    ) {
      return true; // has errors
    }

    return false;
  }

  private validateStates(addressComponent): boolean {
    const countryList = addressComponent.get('countryCode');
    const stateList = addressComponent.get('state');

    if (stateList.hasError('required')) {
      const country = countryList.value;
      const provinceOrState = country === 'CAN' ? 'Province' : 'State';
      this.feedbackService.alert(
        'Please select a ' +
          provinceOrState +
          ' for selected country code: ' +
          country +
          ' before saving the form.',
      );
      return true;
    } else {
      return false;
    }
  }

  private validateZipCodes(addressComponent): boolean {
    const countryList = addressComponent.get('countryCode');
    const zipList = addressComponent.get('zip');

    if (zipList.hasError('required')) {
      const country = countryList.value;
      const provinceOrState = country === 'CAN' ? 'Province' : 'State';
      this.feedbackService.alert(
        'Please enter a zip code for selected country code: ' +
          country +
          ' before saving the form.',
      );
      return true;
    } else {
      return false;
    }
  }

  private validateAttachmentSequence() {
    for (const control of this.contractAttachments.controls) {
      if (control instanceof UntypedFormGroup) {
        Object.keys(control.controls).forEach((field) => {
          if (field === 'sequence') {
            const formControl = control.get(field);
            if (formControl.value === null) {
              markFormGroupTouched(control);
            }
          }
        });
      }
    }
  }

  async setStatusAndRedirectToProjectSummary() {
    this.sendNotApprovedToGAEmail();
  }

  setWUSignatureDate() {
    const datePipe = new DatePipe('en-US');
    const date = datePipe.transform(new Date(), 'MM/dd/yyyy');
    this.subawardForm.controls.washUInformation
      .get('signatureDate')
      .patchValue(date, {
        emitEvent: false,
      });
  }

  signAgreement(index: number) {
    if (this.contractAttachments.at(index).get('signed').value) {
      this.showAgreementSignedModal = true;
      return;
    }
    this.subawardsClient
      .signAgreement(
        this.subawardForm.controls.id.value,
        this.contractAttachments.at(index).get('id').value,
      )
      .pipe(
        catchError((err) => {
          if (err.errorType === 'SIGNATURE_NOT_FOUND') {
            this.showSignatureNotFoundModal.next(true);
          } else if (err.errorType === 'DUPLICATED_BOOKMARK_FOUND') {
            this.duplicatedBookmarkMessage = err.error;
            this.showDuplicatedBookmarkModal.next(true);
          } else {
            this.feedbackService.alert(
              'Unable to Sign Agreement:' + err.error.toString(),
            );
          }
          throw err;
        }),
      )
      .subscribe((result) => {
        this.showSignedSuccessModal.next(true);
        this.contractAttachments.at(index).get('signed').setValue(true);
        this.contractAttachments.at(index).get('id').setValue(null);
        this.contractAttachments
          .at(index)
          .get('fileId')
          .setValue(result.file.id);

        this.save();
      });
    this.setWUSignatureDate();
  }

  sendFinancialRiskEmail() {
    this.showEmailConfirmation = true;

    const riskEmail: FinancialRiskEmail = {
      dunsNumber: this.subawardForm.get('subrecipientInformation.dunsNumber')
        .value,
      uei: this.subawardForm.get('subrecipientInformation.uniqueEntityId')
        .value,
      fromEmail: this.contactForm.get('washUDepartmentAdmin.emailAddress')
        .value,
      wuDeptAdminFirstName: this.contactForm.get(
        'washUDepartmentAdmin.firstName',
      ).value,
      subrecipient: this.subawardForm.get(
        'subrecipientInformation.institutionName',
      ).value,
      subFinancialContactFirstName: this.contactForm.get(
        'institutionalFinancial.firstName',
      ).value,
      subFinancialContactLastName: this.contactForm.get(
        'institutionalFinancial.lastName',
      ).value,
      subFinancialContactEmail: this.contactForm.get(
        'institutionalFinancial.emailAddress',
      ).value,
      washUPrincipalInvestigatorEmail: this.contactForm.get(
        'washUPrincipalInvestigator.emailAddress',
      ).value,
    };

    this.emailsClient.post(riskEmail).subscribe(
      () => {
        this.emailResult.next('Risk Notification Email Sent Successfully.');
        this.subawardForm.controls.washUInformation
          .get('status')
          .patchValue('SRV');
        this.save();
      },
      (error) => {
        this.emailResult.next('Failed to Deliver Email to SPA.');
      },
    );
  }

  async sendRequestCollaboratorInfoEmail() {
    this.showEmailConfirmation = true;

    const collabDataAttachment =
      await this.subawardExcelService.GetCollaboratorSpreadsheetData(
        this.subawardForm.value as Subaward,
        this.project,
      );

    const dataAttachment = {
      data: collabDataAttachment,
      fileName: '',
    };

    const fromEmail = this.contactForm.get(
      'washUDepartmentAdmin.emailAddress',
    ).value;
    const toEmail = this.contactForm.get(
      'departmentalAdmin.emailAddress',
    ).value;
    const wuDeptAdminFirstName = this.contactForm.get(
      'washUDepartmentAdmin.firstName',
    ).value;
    const wuDeptAdminLastName = this.contactForm.get(
      'washUDepartmentAdmin.lastName',
    ).value;
    const wuDeptAdminPhone = this.contactForm.get(
      'washUDepartmentAdmin.phone',
    ).value;
    const wuDeptAdminFax = this.contactForm.get(
      'washUDepartmentAdmin.fax',
    ).value;
    const wuDeptAdminEmail = this.contactForm.get(
      'washUDepartmentAdmin.emailAddress',
    ).value;
    const washUPrincipalInvestigatorFirstName = this.contactForm.get(
      'washUPrincipalInvestigator.firstName',
    ).value;
    const washUPrincipalInvestigatorLastName = this.contactForm.get(
      'washUPrincipalInvestigator.lastName',
    ).value;
    const subrecipient = this.subawardForm.get(
      'subrecipientInformation.institutionName',
    ).value;
    const subPrincipalInvestigatorFirstName = this.contactForm.get(
      'principalInvestigator.firstName',
    ).value;
    const subPrincipalInvestigatorLastName = this.contactForm.get(
      'principalInvestigator.lastName',
    ).value;
    const subDepartmentAdminFirstName = this.contactForm.get(
      'departmentalAdmin.firstName',
    ).value;
    const subDepartmentAdminLastName = this.contactForm.get(
      'departmentalAdmin.lastName',
    ).value;
    const fileNameAttachment = 'Collaborator Information.xlsx';

    this.emailsClient
      .postCollaboratorEmail(
        dataAttachment,
        fromEmail,
        toEmail,
        subrecipient,
        subPrincipalInvestigatorFirstName,
        subPrincipalInvestigatorLastName,
        subDepartmentAdminFirstName,
        subDepartmentAdminLastName,
        wuDeptAdminFirstName,
        wuDeptAdminLastName,
        wuDeptAdminPhone,
        wuDeptAdminEmail,
        wuDeptAdminFax,
        washUPrincipalInvestigatorFirstName,
        washUPrincipalInvestigatorLastName,
        fileNameAttachment,
      )
      .subscribe(
        async () => {
          this.emailResult.next(
            'Request Collaboration Information Email Sent Successfully.',
          );
          this.subawardForm.controls.washUInformation
            .get('status')
            .patchValue('AC');
          await this.save();
        },
        (error) => {
          this.emailResult.next('Failed to Deliver Email to Subrecipient.');
        },
      );
  }

  async sendSubrecipientSignatureEmail() {
    this.emailResult.next('Sending Sub Award to Subrecipient.');
    this.showEmailConfirmation = true;

    const aggreementNumber = this.subawardForm.get(
      'washUInformation.agreementNumber',
    ).value;
    const subPrincipalInvestigatorFirstName = this.contactForm.get(
      'principalInvestigator.firstName',
    ).value;
    const subPrincipalInvestigatorLastName = this.contactForm.get(
      'principalInvestigator.lastName',
    ).value;
    const subPrincipalInvestigatorEmail = this.contactForm.get(
      'principalInvestigator.emailAddress',
    ).value;
    const centralOfficeEmail =
      this.subawardForm.controls.contact.get('centralOfficeEmail').value;
    const washUPrincipalInvestigatorFirstName = this.contactForm.get(
      'washUPrincipalInvestigator.firstName',
    ).value;
    const washUPrincipalInvestigatorLastName = this.contactForm.get(
      'washUPrincipalInvestigator.lastName',
    ).value;
    const washUPrincipalInvestigatorEmail = this.contactForm.get(
      'washUPrincipalInvestigator.emailAddress',
    ).value;
    const wuDeptAdminEmail = this.contactForm.get(
      'washUDepartmentAdmin.emailAddress',
    ).value;
    const subInstitutionAdmin = this.contactForm.get(
      'institutionalAdmin.emailAddress',
    ).value;
    let attachmentId;
    let attachmentName;

    this.contractAttachments.value.forEach((attachment) => {
      if (attachment.fullAgreement) {
        attachmentId = attachment.fileId;
        attachmentName = attachment.fileName;
      }
    });

    if (!attachmentId) {
      this.emailResult.next(
        'The request failed because there were no attachments marked as "Full Agreement."',
      );
      return;
    }

    const fileResponse = await this.downloadService
      .getFile(attachmentId, attachmentName)
      .toPromise();

    if (!fileResponse || !fileResponse.data) {
      this.emailResult.next(
        'Failed to send email because the file was not found.',
      );
      return;
    }

    const dataAttachment = {
      data: fileResponse.data,
      fileName: attachmentName,
    };

    this.emailsClient
      .postSubrecipientSignatureEmail(
        aggreementNumber,
        subPrincipalInvestigatorFirstName,
        subPrincipalInvestigatorLastName,
        subPrincipalInvestigatorEmail,
        washUPrincipalInvestigatorFirstName,
        washUPrincipalInvestigatorLastName,
        washUPrincipalInvestigatorEmail,
        wuDeptAdminEmail,
        subInstitutionAdmin,
        centralOfficeEmail,
        dataAttachment,
        attachmentName,
      )
      .subscribe(
        async () => {
          this.emailResult.next('Sub Award Sent to Subrecipient.');
          await this.delay(this.msToDelayEmailConfirmationMessage); // time for user to see confirmation message(s)
          this.showEmailConfirmation = false;
          this.emailResult.next('Sending...');
          this.subawardForm.controls.washUInformation
            .get('status')
            .patchValue('STS');
          await this.save();
        },
        (error) => {
          this.emailResult.next('Sub Award Failed to Send to Subrecipient.');
        },
      );
  }

  async resendPurchaseOrderNotificationEmail() {
    const fileId =
      this.subawardForm.controls.fullyExecutedAgreement.get('fileId').value;
    const fileName =
      this.subawardForm.controls.fullyExecutedAgreement.get('fileName').value;

    const fileResponse = await this.downloadService
      .getFile(fileId, fileName)
      .toPromise();

    const file = this.BlobToFile(fileResponse.data, fileName);
    await this.sendPurchaseOrderNotificationEmail(file);
  }

  private BlobToFile = (blob: Blob, fileName: string): File => {
    const b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return b as File;
  };

  private async sendPurchaseOrderNotificationEmail(file: File) {
    this.emailResult.next('Sending External PO Notification.');
    this.showEmailConfirmation = true;
    await this.delay(this.msToDelayEmailConfirmationMessage); // time for user to see confirmation message(s)

    if (!file) {
      this.emailResult.next(
        'Failed to deliver External PO Notification because no FEA attachment was found.',
      );
      return;
    }

    const fileData = {
      data: file as Blob,
      fileName: file.name,
    };

    const agreementNumber = this.subawardForm.get(
      'washUInformation.agreementNumber',
    ).value;
    const subPrincipalInvestigatorFirstName = this.contactForm.get(
      'principalInvestigator.firstName',
    ).value;
    const subPrincipalInvestigatorLastName = this.contactForm.get(
      'principalInvestigator.lastName',
    ).value;
    const subPrincipalInvestigatorEmail = this.contactForm.get(
      'principalInvestigator.emailAddress',
    ).value;
    const subFinancialPOCEmail = this.contactForm.get(
      'institutionalFinancial.emailAddress',
    ).value;
    const subDeptAdminEmail = this.contactForm.get(
      'departmentalAdmin.emailAddress',
    ).value;
    const poNumber = this.subawardForm.get(
      'washUInformation.poCoEncumbId',
    ).value;
    const supplierContractId = this.subawardForm.get(
      'washUInformation.supplierContractId',
    ).value;
    const sponsorName = this.subawardForm.get(
      'washUInformation.sponsorName',
    ).value;
    const centralOfficeEmail =
      this.subawardForm.controls.contact.get('centralOfficeEmail').value;
    const subawardId = this.subawardId;
    const dataAttachment = fileData;
    this.emailsClient
      .postPurchaseOrderNotificationEmail(
        agreementNumber,
        poNumber,
        supplierContractId,
        subawardId,
        sponsorName,
        subPrincipalInvestigatorFirstName,
        subPrincipalInvestigatorLastName,
        subPrincipalInvestigatorEmail,
        subDeptAdminEmail,
        subFinancialPOCEmail,
        centralOfficeEmail,
        dataAttachment,
        dataAttachment.fileName,
      )
      .subscribe(
        async () => {
          this.emailResult.next('External PO Notification Sent.');
          this.subawardsService.setPoNotificationSent(true);
          await this.delay(this.msToDelayEmailConfirmationMessage); // time for user to see confirmation message(s)
          this.showEmailConfirmation = false;
          this.emailResult.next('Sending...');
          this.subawardForm.controls.washUInformation
            .get('status')
            .patchValue('SD');
          await this.save();
        },
        (error) => {
          const errorMessage = `Failed to deliver External PO Notification. ${error.error}`;
          this.emailResult.next(errorMessage);
          this.subawardsService.setPoNotificationSent(false);
        },
      );
  }

  async sendBilateralOrUnilateralEmail() {
    let attachmentFile;
    let attachmentId;
    let attachmentName;
    let templateId;
    let facePageFound;
    let facePageFileName;

    this.showEmailConfirmation = true;

    this.contractAttachments.value.forEach((attachment) => {
      if (attachment.fullAgreement) {
        attachmentId = attachment.fileId;
        attachmentName = attachment.fileName;
        attachmentFile = attachment;
      }
    });

    if (!attachmentId) {
      this.emailResult.next(
        'The request failed because there were no attachments marked as "Full Agreement."',
      );
      return;
    }

    this.contractAttachments.value.forEach((attachment) => {
      if (!attachment.fullAgreement && attachment.sequence === '0.0') {
        facePageFound = true; // not the Full Agreement
        facePageFileName = attachment.fileName;
        templateId =
          attachment.utilitiesAttachmentTemplateId != null
            ? attachment.utilitiesAttachmentTemplateId
            : 'unknown';
      }
    });

    if (!facePageFound) {
      this.emailResult.next(
        'The request failed because there is no Face Page attachment.',
      );
      return;
    }

    if (this.modTypeSelection === 'unilateral') {
      // this path triggers two emails; Approve considered clicked
      this.subawardsService.setApproved(true);
      await this.sendSubUnilateralModEmail(
        attachmentId,
        attachmentName,
        attachmentFile,
        false,
      );
    } else if (this.modTypeSelection === 'bilateral') {
      await this.sendSubBilatModEmail(attachmentId, attachmentName);
    } else {
      this.emailResult.next(
        // tslint:disable-next-line:max-line-length
        `The request failed because the Face page attachment (${facePageFileName}) was not specified as Unilateral/Bilateral.The Template Id for this attachment file is: ${templateId}`,
      );
    }
  }

  async resendSubUnilateralModEmail() {
    let attachmentFile;

    this.contractAttachments.value.forEach((attachment) => {
      if (attachment.fullAgreement) {
        attachmentFile = attachment;
      }
    });
    await this.sendSubUnilateralModEmail(
      attachmentFile.fileId,
      attachmentFile.fileName,
      attachmentFile,
      true,
    );
  }

  async sendSubUnilateralModEmail(
    attachmentId: string,
    attachmentName: string,
    attachmentFile: any,
    resend: boolean,
  ) {
    this.emailResult.next('Sending unilateral Sub Award to Subrecipient');
    this.showEmailConfirmation = true;

    const fromEmail = this.userEmail; // This should normally be the OSRS GA who clicks "Approve"
    const awardNumber = this.project.awardNumber;
    const toEmail = this.contactForm.get(
      'institutionalAdmin.emailAddress',
    ).value;
    const additionalToEmail = this.contactForm.get(
      'principalInvestigator.emailAddress',
    ).value;
    const centralOfficeEmail =
      this.subawardForm.controls.contact.get('centralOfficeEmail').value;
    const agreementNumber = this.washUInformation.get('agreementNumber').value;
    const washUPrincipalInvestigatorFullName =
      this.contactForm.get('washUPrincipalInvestigator.firstName').value +
      ' ' +
      this.contactForm.get('washUPrincipalInvestigator.lastName').value;
    const washUPrincipalInvestigatorEmail = this.contactForm.get(
      'washUPrincipalInvestigator.emailAddress',
    ).value;
    const washUDepartmentAdminEmail = this.contactForm.get(
      'washUDepartmentAdmin.emailAddress',
    ).value;
    const subrecipient = this.subawardForm.get(
      'subrecipientInformation.institutionName',
    ).value;
    const subPrincipalInvestigatorFullName =
      this.contactForm.get('principalInvestigator.firstName').value +
      ' ' +
      this.contactForm.get('principalInvestigator.lastName').value;
    const amendment = this.washUInformation.get('amendment').value;

    const fileResponse = await this.downloadService
      .getFile(attachmentId, attachmentName)
      .toPromise();

    if (!fileResponse || !fileResponse.data) {
      this.emailResult.next(
        'Failed to send email because the file was not found.',
      );
      return;
    }

    const dataAttachment = {
      data: fileResponse.data,
      fileName: attachmentName,
    };

    this.subawardsService.setApproved(true); // Approve button clicked

    this.emailsClient
      .postSubUnilatModEmail(
        fromEmail,
        toEmail,
        additionalToEmail,
        agreementNumber,
        amendment,
        awardNumber,
        subrecipient,
        subPrincipalInvestigatorFullName,
        washUPrincipalInvestigatorFullName,
        washUPrincipalInvestigatorEmail,
        washUDepartmentAdminEmail,
        centralOfficeEmail,
        dataAttachment,
        attachmentName,
      )
      .subscribe(
        async () => {
          this.subawardForm.controls.washUInformation
            .get('status')
            .patchValue('STS');
          this.subawardsService.setUnilateralModSent(true);
          this.emailResult.next('Unilateral Sub Award Sent to Subrecipient');
          await this.delay(this.msToDelayEmailConfirmationMessage);
          this.showEmailConfirmation = false;
          this.emailResult.next('Sending...');

          // Save and ensure it completes before sending the next email
          try {
            await this.save();
            await this.delay(this.msToDelay); // Additional delay after save

            if (!resend) {
              await this.sendUnilateralFEAInternalDistributionEmail(
                attachmentFile,
                false,
              );
            }
          } catch (error) {
            console.error(
              'Error during Saving or sending Unilateral FEA Internal Distribution Email:',
              error,
            );
          }
        },
        (error) => {
          this.emailResult.next(
            'Failed to Deliver Email to Subrecipient for Unilateral Sub Award',
          );
          this.subawardsService.setUnilateralModSent(false);
        },
      );
  }

  async sendSubBilatModEmail(attachmentId: string, attachmentName: string) {
    this.emailResult.next('Sending Sub Award to Subrecipient.');
    this.showEmailConfirmation = true;

    const fromEmail = 'wucontracts@email.wustl.edu';
    const awardNumber = this.project.awardNumber;
    const toEmail =
      this.contactForm.get('principalInvestigator.emailAddress').value +
      ',' +
      this.contactForm.get('institutionalAdmin.emailAddress').value;
    const centralOfficeEmail =
      this.subawardForm.controls.contact.get('centralOfficeEmail').value;
    const agreementNumber = this.washUInformation.get('agreementNumber').value;
    const washUPrincipalInvestigatorFullName =
      this.contactForm.get('washUPrincipalInvestigator.firstName').value +
      ' ' +
      this.contactForm.get('washUPrincipalInvestigator.lastName').value;
    const washUPrincipalInvestigatorEmail = this.contactForm.get(
      'washUPrincipalInvestigator.emailAddress',
    ).value;
    const washUDepartmentAdminEmail = this.contactForm.get(
      'washUDepartmentAdmin.emailAddress',
    ).value;
    const subrecipient = this.subawardForm.get(
      'subrecipientInformation.institutionName',
    ).value;
    const subPrincipalInvestigatorFullName =
      this.contactForm.get('principalInvestigator.firstName').value +
      ' ' +
      this.contactForm.get('principalInvestigator.lastName').value;
    const amendment = this.washUInformation.get('amendment').value;

    const fileResponse = await this.downloadService
      .getFile(attachmentId, attachmentName)
      .toPromise();

    if (!fileResponse || !fileResponse.data) {
      this.emailResult.next(
        'Failed to send email because the file was not found.',
      );
      return;
    }

    const dataAttachment = {
      data: fileResponse.data,
      fileName: attachmentName,
    };

    this.emailsClient
      .postSubBilatModEmail(
        fromEmail,
        toEmail,
        agreementNumber,
        amendment,
        subrecipient,
        subPrincipalInvestigatorFullName,
        washUPrincipalInvestigatorFullName,
        washUPrincipalInvestigatorEmail,
        washUDepartmentAdminEmail,
        centralOfficeEmail,
        dataAttachment,
        attachmentName,
      )
      .subscribe(
        async () => {
          this.emailResult.next('Sub Award Sent to Subrecipient');
          await this.delay(this.msToDelayEmailConfirmationMessage); // time for user to see confirmation message(s)
          this.showEmailConfirmation = false;
          this.emailResult.next('Sending...');

          this.subawardForm.controls.washUInformation
            .get('status')
            .patchValue('STS');
          await this.save();
        },
        (error) => {
          this.emailResult.next(
            'Failed to Deliver Email to Subrecipient for Bilateral Sub Award',
          );
        },
      );
  }

  async resendFEAInternalDistributionEmail(feaResend) {
    if (feaResend) {
      // resend Internal Distribution after it fails on FEA Upload
      const feaFileId =
        this.subawardForm.controls.fullyExecutedAgreement.get('fileId').value;
      const feaFileName =
        this.subawardForm.controls.fullyExecutedAgreement.get('fileName').value;

      const fileResponse = await this.downloadService
        .getFile(feaFileId, feaFileName)
        .toPromise();

      if (!fileResponse) {
        this.emailResult.next(
          'Failed to resend email because the file was not found.',
        );
        return;
      }

      const dataAttachment = {
        data: fileResponse.data,
        fileName: feaFileName,
      };

      const result = await this.SetInternalDistributionEmailData(
        feaFileId,
        feaFileName,
      );

      await this.sendFEAInternalDistributionEmail(
        result,
        dataAttachment,
        true, // fea upload is always bilateral
        true,
        this.fixedRate,
      );
    } else {
      // resend Internal Distribution after it fails on Approve button click
      let attachmentFile;

      this.contractAttachments.value.forEach((attachment) => {
        if (attachment.fullAgreement) {
          attachmentFile = attachment;
        }
      });

      if (!attachmentFile.fileId) {
        this.emailResult.next(
          'Failed to resend email because there were no attachments marked as "Full Agreement."',
        );
        return;
      }

      const result = await this.SetInternalDistributionEmailData(
        attachmentFile.fileId,
        attachmentFile.fileName,
      );

      await this.sendFEAInternalDistributionEmail(
        result,
        attachmentFile,
        false, // only MOD/Unilateral triggers Internal Distribution email
        true,
        this.fixedRate,
      );
    }
  }

  async sendUnilateralFEAInternalDistributionEmail(
    attachment: any,
    resend: boolean,
  ) {
    this.showEmailConfirmation = true;
    this.emailResult.next('Sending Internal Distribution.');
    await this.delay(this.msToDelayEmailConfirmationMessage);

    const result = await this.SetInternalDistributionEmailData(
      attachment.fileId,
      attachment.fileName,
    );

    await this.sendFEAInternalDistributionEmail(
      result,
      attachment,
      false,
      resend,
      this.fixedRate,
    );
  }

  async sendBilateralFEAInternalDistributionEmail(
    uploadedFile: any,
    resend: boolean,
  ) {
    this.showEmailConfirmation = true;
    this.emailResult.next('Sending Internal Distribution.');

    const result = await this.SetInternalDistributionEmailData(
      uploadedFile.fileId,
      uploadedFile.file.name,
    );

    await this.sendFEAInternalDistributionEmail(
      result,
      uploadedFile,
      true,
      resend,
      this.fixedRate,
    );
  }

  private async sendFEAInternalDistributionEmail(
    result: any,
    uploadedFile: any,
    bilateral: boolean,
    resend: boolean,
    fixedRate: boolean,
  ) {
    this.emailResult.next('Sending Internal Distribution.');
    this.showEmailConfirmation = true;
    this.emailsClient
      .postApproveFEAInternalDistributionEmail(
        result.dataAttachment,
        result.fileAttachmentName,
        result.fromEmail,
        result.toEmail,
        result.washUPrincipalInvestigatorFirstName,
        result.washUPrincipalInvestigatorLastName,
        result.washUPrincipalInvestigatorEmail,
        result.awardNumber,
        result.agreementNumber,
        result.subPrincipalInvestigatorFirstName,
        result.subPrincipalInvestigatorLastName,
        result.institutionId,
        result.institutionName,
        result.proposalId,
        result.grantWorktag,
        result.awardYear,
        result.costCenter,
        result.costCenterName,
        result.budget,
        result.amendmentNumber,
        result.subawardId,
      )
      .subscribe(
        // Wait on FEA Internal email to complete
        async () => {
          this.emailResult.next('Internal Distribution Sent.');
          await this.subawardsService.setInternalDistributionSent(true);
          await this.delay(this.msToDelayEmailConfirmationMessage); // time for user to see confirmation message(s)
          this.showEmailConfirmation = false;
          this.emailResult.next('Sending...');
          if (bilateral && !fixedRate && !resend) {
            await this.save(); // save state in case PO email fails
            // send External PO Notification only if it's Bilateral
            const invalidSupplierContractIdMessage =
              'External PO Notification Failed due to invalid Supplier Contract ID.';

            if (this.isActiveFeatureCostCenter) {
              const supplierContractId =
                this.subawardForm.controls.washUInformation.get(
                  'supplierContractId',
                ).value;

              if (
                !this.invalidSupplierContractId &&
                supplierContractId &&
                supplierContractId.length > 6
              ) {
                await this.sendPurchaseOrderNotificationEmail(
                  uploadedFile.file,
                );
              } else {
                this.emailResult.next(invalidSupplierContractIdMessage);
                this.showEmailConfirmation = true;
                return;
              }
            }
          } else {
            await this.subawardsService.setInternalDistributionSent(true);
            this.subawardForm.controls.washUInformation
              .get('status')
              .patchValue('SD');
            // save FEA file to Subrecipient.FullyExecutedAgreement
            if (result.fileAttachmentName && result.fileAttachmentId) {
              const feaFile = {
                fileName: result.fileAttachmentName,
                fileId: result.fileAttachmentId,
                subrecipientId: this.subawardForm.controls.id.value,
                dateUploaded: new Date().toISOString(),
              };
              this.fullyExecutedAgreementForm.patchValue(feaFile);
              this.subawardForm.controls.fullyExecutedAgreement.patchValue(
                this.fullyExecutedAgreementForm,
              );
            }
            await this.save();
          }
        },
        (error) => {
          let userFriendlyMessage =
            'Failed to deliver Internal Distribution Email.';

          // Check if the error object has specific properties
          if (error && error.error) {
            const serverError = error.error;

            // Parse server error to get more information
            if (typeof serverError === 'string') {
              userFriendlyMessage += ` Details: ${serverError}`;
            } else if (serverError.message) {
              userFriendlyMessage += ` Details: ${serverError.message}`;
            } else if (typeof serverError === 'object') {
              const errorDetails = JSON.stringify(serverError, null, 2);
              console.error(`Error details: ${errorDetails}`);
              if (serverError.StackTrace) {
                console.error(`Stack trace: ${serverError.StackTrace}`);
              }
            }
          }
          // Handle HTTP status specific messages
          if (error.status) {
            switch (error.status) {
              case 400:
                userFriendlyMessage = 'Request error. Please check your data.';
                break;
              case 404:
                userFriendlyMessage = 'Requested resource not found.';
                break;
              case 500:
                userFriendlyMessage =
                  'Server error occurred. Please contact support.';
                break;
            }
          }
          this.emailResult.next(userFriendlyMessage);
          this.subawardsService.setInternalDistributionSent(false);
        },
      );
  }

  private async SetInternalDistributionEmailData(
    attachmentId: string,
    attachmentName: string,
  ) {
    const fileResponse = await this.downloadService
      .getFile(attachmentId, attachmentName)
      .toPromise();

    if (!fileResponse || !fileResponse.data) {
      this.emailResult.next(
        'Failed to send email because the file was not found.',
      );
      return;
    }
    const attachment = {
      data: fileResponse.data,
      fileName: attachmentName,
    };

    const result: any = {
      dataAttachment: attachment,
      fileAttachmentId: attachmentId,
      fileAttachmentName: attachmentName,
      fromEmail: this.userEmail,
      toEmail: this.contactForm.get('washUDepartmentAdmin.emailAddress').value,
      washUPrincipalInvestigatorFirstName: this.contactForm.get(
        'washUPrincipalInvestigator.firstName',
      ).value,
      washUPrincipalInvestigatorLastName: this.contactForm.get(
        'washUPrincipalInvestigator.lastName',
      ).value,
      washUPrincipalInvestigatorEmail: this.contactForm.get(
        'washUPrincipalInvestigator.emailAddress',
      ).value,
      awardNumber: this.project.awardNumber,
      agreementNumber: this.washUInformation.get('agreementNumber').value,
      subPrincipalInvestigatorFirstName: this.contactForm.get(
        'principalInvestigator.firstName',
      ).value,
      subPrincipalInvestigatorLastName: this.contactForm.get(
        'principalInvestigator.lastName',
      ).value,
      institutionId:
        this.subawardForm.controls.subrecipientInformation.get('institutionId')
          .value,
      institutionName:
        this.subawardForm.controls.subrecipientInformation.get(
          'institutionName',
        ).value,
      proposalId: this.washUInformation.get('proposalId').value,
      costCenter: this.washUInformation.get('costCenterId').value,
      costCenterName: this.washUInformation.get('costCenterName').value,
      grantWorktag: this.washUInformation.get('grantWorkTag').value,
      awardYear: this.washUInformation.get('awardYear').value,
      budget: this.washUInformation.get('issuingFundSubClassBudgetObject')
        .value,
      amendmentNumber: this.washUInformation.get('amendment').value,
      subawardId: this.washUInformation.get('subawardId').value,
    };
    return result;
  }

  async sendReturnedToDAEmail() {
    this.showEmailConfirmation = true;

    let returnComment: string;
    let returnCode: string;
    let returnDescription: string;
    const userName = this.authService.currentUser.name;

    // Get the 1st comment in the array, which should be the one just created
    const comments = this.subawardForm.controls.comments.value[0];

    // Just check that the comment was created by the current user, then get the values.
    // At this point the subrecipientId is null in the new comment, so we cannot check that.
    // We could check the created date against the current date, but that is not necessary. We would have to be careful about time zones also...
    if (comments.createdBy === userName) {
      returnComment = comments.comment;
      returnCode = comments.returnCode;
    }

    returnDescription = this.returnCodes.find(
      (x) => x.code === returnCode,
    ).reason;

    const returnedToDAEmail: ReturnedToDAEmail = {
      fromEmail: this.userEmail,
      toEmail: this.contactForm.get('washUDepartmentAdmin.emailAddress').value,
      proposalId: this.washUInformation.get('proposalId').value,
      issuingFund: this.washUInformation.get('fund').value,
      washUDepartmentAdminFullName:
        this.contactForm.get('washUDepartmentAdmin.firstName').value +
        ' ' +
        this.contactForm.get('washUDepartmentAdmin.lastName').value,
      washUPrincipalInvestigatorFullName:
        this.contactForm.get('washUPrincipalInvestigator.firstName').value +
        ' ' +
        this.contactForm.get('washUPrincipalInvestigator.lastName').value,
      subPrincipalInvestigatorFullName:
        this.contactForm.get('principalInvestigator.firstName').value +
        ' ' +
        this.contactForm.get('principalInvestigator.lastName').value,
      awardYear: this.washUInformation.get('awardYear').value,
      institutionId:
        this.subawardForm.controls.subrecipientInformation.get('institutionId')
          .value,
      institutionName:
        this.subawardForm.controls.subrecipientInformation.get(
          'institutionName',
        ).value,
      issuingDepartment:
        this.subawardForm.controls.washUInformation.get('issuingDepartment')
          .value,
      costCenter:
        this.subawardForm.controls.washUInformation.get('costCenterId').value,
      costCenterName:
        this.subawardForm.controls.washUInformation.get('costCenterName').value,
      grantWorktag:
        this.subawardForm.controls.washUInformation.get('grantWorkTag').value,
      budget: this.subawardForm.controls.washUInformation.get(
        'issuingFundSubClassBudgetObject',
      ).value,
      amendmentNumber:
        this.subawardForm.controls.washUInformation.get('amendment').value,
      returnCode,
      returnReason: returnDescription,
      returnComment,
    };

    this.emailsClient.postReturnedToDAEmail(returnedToDAEmail).subscribe(
      async () => {
        this.emailResult.next('Subaward Record Returned to DA.');
      },
      (error) => {
        this.emailResult.next('Failed to Deliver Email to DA Recipient.');
      },
    );
  }

  async sendNotApprovedToGAEmail() {
    const agreementNumber = this.washUInformation.get('agreementNumber').value;
    const osrsGAFullName = this.washUInformation.get('assignedGA').value;

    if (
      agreementNumber === null ||
      agreementNumber === undefined ||
      agreementNumber === ''
    ) {
      this.feedbackService.alert(
        'Cannot complete NOT APPROVED process. No WU Agreement Number has been entered.',
      );
      return;
    }

    if (
      osrsGAFullName === null ||
      osrsGAFullName === undefined ||
      osrsGAFullName === ''
    ) {
      this.feedbackService.alert(
        'Cannot complete NOT APPROVED process. No OSRS GA has been selected',
      );
      return;
    }

    this.emailRecipient = this.userProfileList.get(osrsGAFullName).userEmail;

    if (
      this.emailRecipient === null ||
      this.emailRecipient === undefined ||
      this.emailRecipient === ''
    ) {
      this.feedbackService.alert(
        'Cannot complete NOT APPROVED process. No email found for OSRS GA: ' +
          osrsGAFullName,
      );
      return;
    }
    let osrsGADisplayName: string;

    if (osrsGAFullName.includes(',')) {
      const lastAndFirst = osrsGAFullName.split(',', 2);
      osrsGADisplayName = lastAndFirst[1] + ' ' + lastAndFirst[0];
    } else {
      osrsGADisplayName = osrsGAFullName;
    }

    this.redirectToProjectSummary = true;
    this.showEmailConfirmation = true;

    const notApprovedEmail: NotApprovedToGAEmail = {
      fromEmail: this.userEmail,
      toEmail: this.emailRecipient,
      agreementNumber: this.washUInformation.get('agreementNumber').value,
      washUOSRSGAFullName: osrsGADisplayName,
    };

    this.emailsClient.postNotApprovedToGaEmail(notApprovedEmail).subscribe(
      async () => {
        this.emailResult.next(
          'Email Notification Sent Successfully to OSRS GA',
        );
        this.subawardForm.controls.washUInformation
          .get('status')
          .patchValue('OR');
        await this.save();
      },
      (error) => {
        this.emailResult.next('Failed to Deliver Email to  OSRS GA');
        this.redirectToProjectSummary = false;
      },
    );
  }

  async copySignatory() {
    // Bug Fix 777 Copy Primary place of performance address data from
    // Authorized Institutional Official (Signatory) of Collaborating Institution
    // on Subaward Contacts tab to Collaborator Primary Place of Performance
    // on Subaward Details tab

    // Copy address, Institution Name & Central Office Email (dd_12_2024) component values
    // *********************************************************
    const selectedInstitutionName =
      this.subawardForm.controls.subrecipientInformation.get(
        'institutionName',
      ).value;

    const centralOfficeEmail =
      this.subawardForm.controls.contact.get('centralOfficeEmail').value;

    const signatoryAddress =
      this.subawardForm.controls.contact.get('signatory.address').value;

    // Paste Values
    // *********************************************************
    this.subawardForm.controls.subrecipientInformation
      .get('placeOfPerformance.institutionName')
      .patchValue(selectedInstitutionName);

    this.subawardForm.controls.subrecipientInformation
      .get('placeOfPerformance.emailAddress')
      .patchValue(centralOfficeEmail);

    this.subawardForm.controls.subrecipientInformation
      .get('placeOfPerformance.address')
      .patchValue(signatoryAddress);

    this.subawardForm.controls.subrecipientInformation
      .get('placeOfPerformance.placeDiffersFromContactsPage')
      .patchValue(false);
  }

  async sameAsGrantPeriod() {
    let doCopy = false;

    const checkedValue = this.subawardForm.controls.subrecipientInformation.get(
      'budget.sameAsGrantPeriod',
    ).value;

    if (checkedValue == null || checkedValue == false) {
      // this gets the value before the click, so value = false or null is actually checked
      // and value == true is actually unchecked.
      doCopy = true;
    }

    if (doCopy) {
      const projGrantFromDate = this.datePipe.transform(
        this.project.grantFromDate,
        'MM-dd-yyyy',
      );

      const projGrantThruDate = this.datePipe.transform(
        this.project.grantThruDate,
        'MM-dd-yyyy',
      );

      this.subawardForm.controls.subrecipientInformation
        .get('budget.budgetFromDate')
        .setValue(projGrantFromDate);

      this.subawardForm.controls.subrecipientInformation
        .get('budget.budgetThruDate')
        .setValue(projGrantThruDate);
    }
  }

  async confirmExcelImport() {
    // this is 'Upload and Overwrite'
    this.subawardForm.patchValue(this.excelImportSubaward);
    this.showConfirmationExcelImport = false;
    this.subawardForm.controls.washUInformation.get('status').patchValue('CIC');
    await this.save();
  }

  async discardExcelImport() {
    // this is 'Upload Only'
    this.showConfirmationExcelImport = false;
    this.excelImportSubaward = undefined;
    this.subawardForm.controls.washUInformation.get('status').patchValue('CIC');
    await this.save();
  }

  discardExcelUpload() {
    this.showConfirmationExcelImport = false;
    this.excelImportSubaward = undefined;
    this.subawardForm.controls.subrecipientInformation
      .get('institutionInformationFileId')
      .patchValue(null);
    this.subawardForm.controls.subrecipientInformation
      .get('institutionInformationFileName')
      .patchValue(null);
    this.subawardForm.controls.subrecipientInformation
      .get('institutionInformationDateUploaded')
      .patchValue(null);
  }

  loadInstitutionalInformation(subaward: Subaward) {
    if (
      subaward.institutionOfficers &&
      subaward.institutionOfficers.length > 0
    ) {
      // set new Officers
      const newOfficers = subaward.institutionOfficers;
      const currentUserName = this.authService.currentUser.name;

      newOfficers.forEach((officer) => {
        officer.institutionId =
          this.subawardForm.controls.subrecipientInformation.get(
            'institutionId',
          ).value;
        officer.subrecipientId = this.subawardForm.controls.id.value;
        officer.isActive = true;
        officer.createdBy = currentUserName;
        officer.modifiedBy = currentUserName;
        officer.createdDate = new Date();
        officer.modifiedDate = new Date();
      });

      // compare new and existing Officers
      const institutionOfficers =
        this.subawardForm.controls.institutionOfficers.value;

      newOfficers.forEach((newItem) => {
        const exists = institutionOfficers.some(
          (existingItem) =>
            existingItem.officerName === newItem.officerName &&
            existingItem.compensation === newItem.compensation,
        );
        if (!exists) {
          institutionOfficers.push(newItem);
        }
      });
      subaward.institutionOfficers = institutionOfficers;
    }

    this.excelImportSubaward = subaward;
    this.showConfirmationExcelImport = true;
  }

  closeEmailConfirmationModal() {
    this.showEmailConfirmation = false;
    this.emailResult.next('Sending...');
  }

  viewOnlyUser(): boolean {
    return (
      this.authService.hasPermission('ReadOnlyGlobal') || this.viewOnlyDAUser()
    );
  }

  viewOnlyDAUser(): boolean {
    return (
      this.authService.hasPermission('DepartmentAdminGlobalAccess') &&
      !this.isInDAStatus()
    );
  }

  isInDAStatus(): boolean {
    const status =
      this.subawardForm.controls.washUInformation.get('status').value;

    return ['IP', 'AC', 'CIC', 'PSO', 'SRV', 'SO', 'RC', 'RD'].includes(status);
  }

  canChangesToCurrentYear() {
    let allowAccess = false;
    const daAllowedStatuses = ['SD', 'VD', 'IA'];
    const status =
      this.subawardForm.controls.washUInformation.get('status').value;

    if (!this.isCloneAction) {
      if (this.authService.hasPermission('AccessButtonChangesToCurrentYear')) {
        allowAccess = true;
      } else {
        allowAccess = false;
      }

      if (
        this.authService.hasPermission('DepartmentAdminGlobalAccess') &&
        daAllowedStatuses.includes(status)
      ) {
        allowAccess = true;
      }
    }
    return allowAccess;
  }

  selectAttachments() {
    if (!this.subawardForm.controls.id.value) {
      this.showUnsavedChangesModal = true;
    } else {
      const id = this.subawardForm.controls.id.value;
      this.router.navigateByUrl(`/subaward/${id}/select-attachments`);
    }
  }

  async generateFullAgreementPDF() {
    if (!this.subawardForm.value.contractAttachments.length) {
      this.showNoAttachmentsModal = true;
      return;
    }

    await this.save(); // must save to include updates to attachments in PDF (signature added, for example)

    this.showFullAgreementProgress = true;
    const id = this.subawardForm.controls.id.value;
    this.subawardsClient
      .generateFullAgreementPdf(id)
      .subscribe(async (result) => {
        await this.showGenerateAgreementPdfProgress(result.jobId);
      });
  }

  async showGenerateAgreementPdfProgress(jobId: string) {
    const connection = new HubConnectionBuilder()
      .withUrl(`${this.baseUrl}/jobStatus`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();

    this.generateAgreementStatusMessage.next('Queued');

    connection.on('Error', async (_, message: string) => {
      await connection.stop();
      this.showGenerateAgreementError = true;
      this.showFullAgreementProgress = false;
      this.generateAgreementStatusMessage.next(message);
      return;
    });

    connection.on(
      'UpdateProgress',
      async (_, completed: boolean, message: string, errored: boolean) => {
        this.generateAgreementStatusMessage.next(message);

        if (completed) {
          await connection.stop();
          this.showFullAgreementProgress = false;
          this.refresh();
        }
      },
    );

    await connection.start();
    connection.invoke('register', jobId);
  }

  async feaUploaded(uploadedFile: any) {
    this.subawardsService.setFeaUploaded(true);
    this.feaFileUploaded = true;
    this.fullyExecutedAgreementForm.controls.fileId.patchValue(
      uploadedFile.fileId,
    );
    this.fullyExecutedAgreementForm.controls.fileName.patchValue(
      uploadedFile.file.name,
    );
    this.fullyExecutedAgreementForm.controls.subrecipientId.patchValue(
      this.subawardForm.controls.id.value,
    );

    this.subawardForm.controls.fullyExecutedAgreement.patchValue(
      this.fullyExecutedAgreementForm,
    );

    await this.save().then(async () => {
      // PO Number should NOT block Internal FEA Distribution
      await this.sendBilateralFEAInternalDistributionEmail(uploadedFile, false);
    });
  }

  refresh() {
    this.subawardsClient
      .get(this.subawardId)
      .pipe(this.feedbackService.providePageLoadFeedback())
      .subscribe((subaward) => this.setSubawardForm(subaward));
  }

  sendFcoiEmail() {
    this.showEmailConfirmation = true;
    this.emailResult.next('Sending...');
    const subaward = this.subawardForm.value;
    const institutionName =
      this.subawardForm.controls.subrecipientInformation.get(
        'institutionName',
      ).value;

    const centralOfficeEmail =
      this.subawardForm.controls.contact.get('centralOfficeEmail').value;

    this.emailsClient
      .fCOIEmail({
        agreementNumber: subaward.washUInformation.agreementNumber,
        subPI: subaward.contact.principalInvestigator.emailAddress,
        washUPI: subaward.contact.washUPrincipalInvestigator.emailAddress,
        washUDA: subaward.contact.washUDepartmentAdmin.emailAddress,
        institutionName,
        subAdmin: subaward.contact.institutionalAdmin.emailAddress,
        centralAdminEmail: centralOfficeEmail,
      })
      .subscribe({
        complete: () => {
          this.emailResult.next('FCOI Email Sent Successfully.');
          this.subawardForm.controls.washUInformation.patchValue({
            status: 'RA',
          });
          const emailSentDate = this.datePipe.transform(
            new Date(),
            'MM/dd/yyyy',
          );
          this.subawardForm.controls.washUInformation.patchValue({
            coiEmailSent: emailSentDate,
          });
          this.subawardForm.controls.washUInformation.patchValue({
            coiEmplId: this.authService.currentUser.name,
          });
        },
        error: (err) => {
          this.emailResult.next('FCOI Email Failed to Deliver.');
        },
      });
  }

  routeForSignature() {
    this.subawardForm.controls.washUInformation.patchValue({
      status: 'PA',
    });
    this.showRouteForSignatureClickedModal = true;
  }

  clearSupplierContractIdWarningMessage() {
    this.showSupplierContractIdModal = false;
    this.supplierContractIdWarningMessage = [];
  }

  copyPoCoRequestId(supplierContractId: string) {
    const currentSupplierContractId =
      this.subawardForm.controls.washUInformation.get(
        'supplierContractId',
      ).value;
    if (supplierContractId && !currentSupplierContractId) {
      this.subawardsService.setSupplierContractId(supplierContractId);
      this.subawardForm.controls.washUInformation
        .get('supplierContractId')
        .patchValue(supplierContractId);
    }
  }

  validateGrantWorkTag(grantWorkTagId: string) {
    this.validatingGrantWorkTag = true;
    this.subawardsClient.getGrant(grantWorkTagId).subscribe((result) => {
      if (result) {
        if (result.warnings.length === 0) {
          this.contactForm.controls.washUPrincipalInvestigator
            .get('firstName')
            .patchValue(result.washUPiContact.firstName);
          this.contactForm.controls.washUPrincipalInvestigator
            .get('middleName')
            .patchValue(result.washUPiContact.middleName);
          this.contactForm.controls.washUPrincipalInvestigator
            .get('lastName')
            .patchValue(result.washUPiContact.lastName);
          this.contactForm.controls.washUPrincipalInvestigator
            .get('nameSuffix')
            .patchValue(result.washUPiContact.nameSuffix);
          this.contactForm.controls.washUPrincipalInvestigator
            .get('phone')
            .patchValue('');
          this.contactForm.controls.washUPrincipalInvestigator
            .get('fax')
            .patchValue('');
          this.contactForm.controls.washUPrincipalInvestigator
            .get('emailAddress')
            .patchValue(result.washUPiContact.emailAddress);
          this.contactForm.controls.washUPrincipalInvestigator
            .get('address')
            .patchValue({});
          this.contactForm.controls.washUPrincipalInvestigator
            .get('campusCD')
            .patchValue(result.washUPiContact.campusCD);
          this.contactForm.controls.washUPrincipalInvestigator
            .get('campusBox')
            .patchValue(result.washUPiContact.campusBox);
          this.contactForm.controls.washUPrincipalInvestigator
            .get('principalInvestigatorDepartment')
            .patchValue(result.washUPiContact.principalInvestigatorDepartment);
          this.contactForm.controls.washUPrincipalInvestigator
            .get('primaryDivision')
            .patchValue('');
          this.subawardForm.controls.washUInformation
            .get('costCenterId')
            .patchValue(result.costCenterId);
          this.subawardForm.controls.washUInformation
            .get('costCenterName')
            .patchValue(result.costCenterName);
          this.subawardForm.controls.washUInformation
            .get('grantWorkTag')
            .patchValue(result.grantWorkTag);
          const piName =
            result.washUPiContact.firstName +
            ' ' +
            result.washUPiContact.lastName;
          this.subawardForm.controls.washUInformation
            .get('principalInvestigatorName')
            .patchValue(piName);
          this.validatingGrantWorkTag = false;
          this.save();
        } else {
          result.warnings.forEach((warning) => {
            this.grantWorkTagWarningMessage.push(warning);
          });
          this.showGrantWorkTagModal = true;
          this.validatingGrantWorkTag = false;
        }
      }
    });
  }

  clearGrantWorkTagWarningMessage() {
    this.showGrantWorkTagModal = false;
    this.grantWorkTagWarningMessage = [];
  }

  saveSubaward() {
    this.save();
  }

  setActiveTab(tabName: string) {
    Object.keys(this.activeTab).forEach((key) => {
      this.activeTab[key] = false;
    });
    this.activeTab[tabName] = true;
  }

  private setMonitoringQuestionsVisibility() {
    this.questionIsVisible =
      this.subawardForm.controls.washUInformation.get('awardYear').value >= 2;
  }
}
