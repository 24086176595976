import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FeedbackService } from '../../form-layout/feedback.service';
import { ExcelExportService } from '../excel-export.service';
import {
  CchRollupReportClient,
  CchRollupReportResponse,
  CchRollupReportViewModel,
} from '../../api.service';

interface SummaryRow {
  labelSubmitted: string | undefined;
  valueSubmitted: number | undefined;
  labelExecuted: string | undefined;
  valueExecuted: number | undefined;
}

@Component({
  selector: 'subs-cch-rollup-report',
  templateUrl: './cch-rollup-report.component.html',
  providers: [FeedbackService],
})
export class CchRollupReportComponent {
  alertSubject = this.feedbackService.alerts;
  submitState = this.feedbackService.submitState;

  searchForm = this.fb.group({
    dateFrom: ['', Validators.required],
    dateThru: ['', Validators.required],
    cchStart: ['', Validators.pattern('^[0-9]*$')],
    cchEnd: ['', Validators.pattern('^[0-9]*$')],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService,
    private reportService: CchRollupReportClient,
    private excelExportService: ExcelExportService,
  ) {}

  search() {
    this.feedbackService.beginLoading();

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      this.reportService
        .get(
          new Date(this.searchForm.controls.dateFrom.value),
          new Date(this.searchForm.controls.dateThru.value),
          this.searchForm.controls.cchStart.value,
          this.searchForm.controls.cchEnd.value,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(async val => await this.generateReport(val));
    }
  }

  clear() {
    this.searchForm.controls.dateFrom.setValue('');
    this.searchForm.controls.dateThru.setValue('');
    this.searchForm.controls.cchStart.setValue('');
    this.searchForm.controls.cchEnd.setValue('');
    this.feedbackService.clearAlert();
  }

  async generateReport(results: CchRollupReportResponse) {
    if (results.submittedAndExecutedByCchRollup.length === 0) {
      this.feedbackService.alert('There are no results for your search');
      return;
    }
    const columns = [
      {
        header: 'Subaward Cost Center Rollup Number',
        key: 'cch',
        width: 30,
      },
      {
        header: 'Subaward Cost Center Rollup Name',
        key: 'cchName',
        width: 60,
      },
      {
        header: 'Total Subs Submitted',
        key: 'submittedTotal',
        width: 30,
      },
      {
        header: 'New Subs Executed',
        key: 'executedNew',
        width: 30,
      },
      {
        header: 'Modifications Executed',
        key: 'executedMod',
        width: 30,
      },
      {
        header: 'Total Subs Executed',
        key: 'executedTotal',
        width: 30,
      },
    ];

    const data = this.generateReportData(
      results.submittedAndExecutedByCchRollup,
    );
    const summary = this.generateSummaryRowsArray(results);

    await this.excelExportService.generateExcel(
      'SUBSDataByCostCenterRollup',
      'SUBS Data by Cost Center Rollup',
      data,
      columns,
      {
        value: `Date Range: ${this.searchForm.controls.dateFrom.value} - ${this.searchForm.controls.dateThru.value},
        CCH Start: ${this.searchForm.controls.cchStart.value},
        CCH End: ${this.searchForm.controls.cchEnd.value}`,
      },
      this.addStylesAndSummaryRows,
      summary,
    );
  }

  private generateReportData(results: Array<CchRollupReportViewModel>) {
    const data = [];
    results.forEach(e => {
      data.push([
        e.cchRollup,
        e.cchRollupName,
        e.totalSubmitted,
        e.newExecuted,
        e.modsExecuted,
        e.totalExecuted,
      ]);
    });
    return data;
  }

  private generateSummaryRowsArray(results: CchRollupReportResponse) {
    const summary: SummaryRow = {
      labelSubmitted: 'Grand Total Submitted: ',
      valueSubmitted: results.grandTotalSubmitted,
      labelExecuted: 'Grand Total Executed: ',
      valueExecuted: results.grandTotalExecuted,
    };
    return summary;
  }

  private addStylesAndSummaryRows(worksheet, summary) {
    const row = worksheet.addRow([
      '',
      '',
      summary.labelSubmitted + summary.valueSubmitted,
      '',
      '',
      summary.labelExecuted + summary.valueExecuted,
    ]);

    row.font = {
      size: 14,
      bold: true,
    };

    row.eachCell(cell => {
      cell.alignment = { vertical: 'top', horizontal: 'right' };
      cell.border = {
        top: {
          style: 'thin',
        },
        left: {
          style: 'thin',
        },
        bottom: {
          style: 'thin',
        },
        right: {
          style: 'thin',
        },
      };
    });
  }
}
