<subs-form-layout>
  <subs-form-content>
    <div class="card">
      <div class="card-header">Security Roles</div>
      <div class="card-block">
        <div class="card-title">Add New User</div>
        <div class="clr-row clr-align-items-end" [formGroup]="newUserProfile">
          <div class="clr-col-1" style="text-align: center">
            <button
              data-test-id="search-wu-pi"
              type="button"
              class="btn btn-icon"
              aria-label="warning"
              title="Employee LookUp"
              (click)="lookupEmployee()"
            >
              <clr-icon shape="search"></clr-icon>
            </button>
          </div>
          <subs-static-text
            controlName="wustlId"
            label="WUSTL ID"
            labelLength="3"
            class="clr-col-12 clr-col-md-2"
            maxLength="10"
            errorMessage="WUSTL ID must be a number."
          >
          </subs-static-text>
          <subs-static-text
            controlName="employeeName"
            label="Name"
            labelLength="3"
            class="clr-col-12 clr-col-md-2"
            maxLength="10"
            errorMessage=""
          >
          </subs-static-text>
          <subs-static-text
            controlName="email"
            label="Email"
            labelLength="3"
            class="clr-col-12 clr-col-lg-2"
            maxLength="10"
            errorMessage=""
          >
          </subs-static-text>
          <subs-item-lookup-select
            class="clr-col-12 clr-col-md-2"
            [options]="userRoles"
            controlName="role"
            label="Role"
            labelLength="3"
            errorMessage="A selection is required."
          ></subs-item-lookup-select>
          <div
            class="clr-col-12 clr-col-lg-3"
            style="display: flex; justify-content: flex-end"
          >
            <button
              class="btn btn-sm"
              (click)="onAdd()"
              style="margin-right: 2em"
            >
              Add user
            </button>
            <button class="btn btn-sm" (click)="onClear()">Clear</button>
          </div>
        </div>
      </div>
      <div class="card-block" style="padding-top: 0px">
        <div class="clr-row clr-align-items-end">
          <div class="clr-col-12 clr-col-lg-10">
            <div class="card-title">Security Profiles</div>
          </div>
          <div class="clr-col-12 clr-col-lg-2">
            <div
              *ngIf="this.userProfiles && this.userProfiles.length > 0"
              [formGroup]="searchForm"
            >
              <subs-checkbox
                label="Show Inactive"
                controlName="showInactive"
                class="clr-col"
                labelLength="5"
                [style]="safeInputStyle"
              >
              </subs-checkbox>
            </div>
          </div>
        </div>

        <clr-datagrid data-test-id="noa-grid" style="margin-top: 12px">
          <clr-dg-column
            ><ng-container *clrDgHideableColumn="{ hidden: false }">
              WUSTL ID
            </ng-container>
            <clr-dg-string-filter
              [clrDgStringFilter]="wustlIdFilter"
            ></clr-dg-string-filter>
          </clr-dg-column>
          <clr-dg-column
            [clrDgSortBy]="nameComparator"
            [clrDgSortOrder]="descSort"
            ><ng-container *clrDgHideableColumn="{ hidden: false }">
              Employee Name
            </ng-container>
            <clr-dg-string-filter
              [clrDgStringFilter]="nameFilter"
            ></clr-dg-string-filter
          ></clr-dg-column>
          <clr-dg-column [clrDgSortBy]="roleComparator"
            ><ng-container *clrDgHideableColumn="{ hidden: false }">
              User Role
            </ng-container>
            <clr-dg-string-filter
              [clrDgStringFilter]="roleFilter"
            ></clr-dg-string-filter
          ></clr-dg-column>
          <clr-dg-column>
            Email
            <clr-dg-string-filter
              [clrDgStringFilter]="emailFilter"
            ></clr-dg-string-filter>
          </clr-dg-column>
          <clr-dg-column>
            Job Title
            <clr-dg-string-filter
              [clrDgStringFilter]="jobTitleFilter"
            ></clr-dg-string-filter>
          </clr-dg-column>
          <clr-dg-column> Status </clr-dg-column>
          <clr-dg-column> Date of Action </clr-dg-column>
          <clr-dg-column> Notes </clr-dg-column>
          <clr-dg-column>
            Signature File
            <a
              role="tooltip"
              aria-haspopup="true"
              class="tooltip tooltip-md tooltip-bottom-left"
            >
              <clr-icon shape="info-circle" size="22"></clr-icon>
              <span class="tooltip-content"
                >Only PNG and JPG/JPEG images can be selected.</span
              >
            </a>
          </clr-dg-column>

          <clr-dg-row
            *clrDgItems="
              let userProfile of userProfiles.controls;
              let i = index
            "
            [formGroup]="userProfile"
          >
            <clr-dg-action-overflow
              *ngIf="
                osrsAuthorityAccess && userProfile.value['signatureFileName']
              "
            >
              <button
                class="action-item"
                (click)="download(userProfile.value['id'])"
                clrPopoverCloseButton
                data-test-id="signature-download"
              >
                Download Signature
              </button>
              <button
                class="action-item"
                (click)="deleteSignature(userProfile.value['id'])"
                clrPopoverCloseButton
                data-test-id="signature-delete"
              >
                Delete Signature
              </button>
            </clr-dg-action-overflow>

            <clr-dg-cell>
              {{ userProfile.value['wustlId'] }}
            </clr-dg-cell>
            <clr-dg-cell>
              {{ userProfile.value['employeeName'] }}
            </clr-dg-cell>
            <clr-dg-cell>
              <subs-item-lookup-select
                class="clr-col"
                [options]="userRoles"
                controlName="role"
                labelLength="0"
                errorMessage="A selection is required."
              ></subs-item-lookup-select>
            </clr-dg-cell>
            <clr-dg-cell>
              {{ userProfile.value['email'] }}
            </clr-dg-cell>
            <clr-dg-cell>
              {{ userProfile.value['jobTitle'] }}
            </clr-dg-cell>
            <clr-dg-cell>
              <subs-select
                containerStyle="margin-top: 0"
                labelLength="0"
                controlName="isActive"
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </subs-select>
            </clr-dg-cell>
            <clr-dg-cell>{{
              userProfile.value['dateOfAction'] | date
            }}</clr-dg-cell>
            <clr-dg-cell>
              <subs-input
                containerStyle="margin-top: 0"
                labelLength="0"
                controlName="notes"
                maxLength="254"
              ></subs-input
            ></clr-dg-cell>
            <clr-dg-cell>
              <div *ngIf="osrsAuthorityAccess">
                <subs-upload-button
                  *ngIf="!userProfile.value['signatureFileName']"
                  [acceptedMimeTypes]="['image/jpeg', 'image/png']"
                  #uploadButton
                  (uploaded)="fileUploaded($event, userProfile.value['id'])"
                  id="signature-upload"
                  >Add</subs-upload-button
                >
                {{ userProfile.value['signatureFileName'] }}
              </div>
            </clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
      </div>
    </div>
  </subs-form-content>
  <subs-form-buttons>
    <button
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="save()"
      data-test-id="submit-security"
    >
      Save
    </button>
    <button class="btn btn-outline" (click)="downloadReport()">Export</button>
    <button
      class="btn btn-outline"
      (click)="downloadSecurityRolesSpreadsheet()"
    >
      Download Security Roles Spreadsheet
    </button>
  </subs-form-buttons>
</subs-form-layout>
<subs-employee-lookup
  [allowSearchByEmployeeId]="false"
  data-test-id="employee-lookup"
>
</subs-employee-lookup>
