import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SubrecipientStatusVm } from '../../api.service';
import { SubStatusService } from '../../utilities/subrecipient-status/sub-status.service';

@Component({
  selector: 'subs-fcoi',
  templateUrl: './fcoi.component.html',
  styleUrls: ['./fcoi.component.scss'],
})
export class FcoiComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Output() fcoiEmailClicked = new EventEmitter();

  disclusureIsEmpty: boolean;
  educationIsEmpty: boolean;
  coiPolicy: boolean;
  activeStatusList: SubrecipientStatusVm[] = [];

  constructor(
    private datePipe: DatePipe,
    private subStatusService: SubStatusService,
  ) {}

  isDateEnabled(): boolean {
    return (
      !this.disclusureIsEmpty &&
      !this.educationIsEmpty &&
      this.coiPolicy === false
    );
  }

  ngOnInit(): void {
    this.subStatusService.ActiveStatuses.subscribe(async value => {
      if (value !== undefined && value !== null) {
        this.activeStatusList = value;
      }
    });
    this.form.get('disclosureCompletionDate').valueChanges.subscribe(value => {
      if (!value) {
        this.disclusureIsEmpty = true;
      } else {
        this.disclusureIsEmpty = false;
      }
      this.resetOldApprovalValue();
    });

    this.form
      .get('educationModuleCompletionDate')
      .valueChanges.subscribe(value => {
        if (!value) {
          this.educationIsEmpty = true;
        } else {
          this.educationIsEmpty = false;
        }
        this.resetOldApprovalValue();
      });

    this.form.get('coiPolicy').valueChanges.subscribe(value => {
      this.coiPolicy = value;
      this.resetOldApprovalValue();
    });

    this.disclusureIsEmpty = !this.form.controls.disclosureCompletionDate.value;
    this.educationIsEmpty = !this.form.controls.educationModuleCompletionDate
      .value;
    this.coiPolicy = this.form.controls.coiPolicy.value;
  }

  markReceived() {
    this.form.controls.subReceived.patchValue(
      this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
    );
    this.form.controls.status.patchValue('SR');
  }

  private resetOldApprovalValue() {
    if (!this.isDateEnabled()) {
      this.form.controls.approvalDate.setValue('');
    }
  }
}
